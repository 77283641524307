/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { useTranslation } from 'react-i18next';
import { useState } from 'react';

import { UsersService } from 'src/services/users.service';
import { Link, useHistory } from 'react-router-dom';
import Modal from 'react-modal';
import Button from 'src/common/Button/index';
import { i18n } from 'src/i18n';
import Loader from '../../../Loader/Loader';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)'
  }
};

type LoginProps = {
  emailInput: any;
  emailInputHandler: any;
  isTwilioVideo: any;
  roomName: any;
  setLogin: any;
  setAuthToken: any;
};

export const HandleLogin = ({
  emailInput,
  emailInputHandler,
  isTwilioVideo,
  roomName,
  setLogin,
  setAuthToken
}: LoginProps): JSX.Element => {
  const { t } = useTranslation();
  const history = useHistory();

  const [errorToken, setErrorToken] = useState<any>();
  const [userId, setUserId] = useState('');
  const [emailToken, setEmailToken] = useState<any>();
  const [marked, setMarked] = useState(false);
  const [emailModalInput, setEmailModalInput] = useState('');
  const [handleLoader, setHandleLoader] = useState(false);
  const [isOldUser, setIsOldUser] = useState(false);
  const [markedOld, setMarkedOld] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [isCodeMessage, setIsCodeMessage] = useState(false);
  const [isUserExist, setIsUserExist] = useState(false);
  const [isEmailConfirmed, setIsEmailConfirmed] = useState(false);

  const [passwordInput, setpasswordInput] = useState('');
  const [passwordToucing, setPasswordToucing] = useState(false);

  const inputEmailValidation =
    emailInput.trim() !== '' && emailInput.includes('@');
  const inputPasswordVlidation = passwordInput.length > 6;
  const passwordValidation = !inputPasswordVlidation && passwordToucing;

  let logged: any;

  const passwordInputHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setpasswordInput(e.currentTarget.value);
  };

  const emailModalInputHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmailModalInput(e.currentTarget.value.toLowerCase());
  };

  const submitEmail = async () => {
    const user = { email: emailModalInput };

    const result: any = await UsersService.upadteUserInfoForEmailReset(
      user,
      userId,
      emailToken,
      errorToken
    );

    if (result?.details === 'User Already Exist') {
      setIsUserExist(true);
    } else {
      setIsCodeMessage(true);
      await UsersService.requestPasswordReset(emailModalInput, errorToken);
      setTimeout(() => setMarked(false), 30000);
      history.push(`/${i18n.language}/Login`);
    }
  };

  const handleLogin = async (e: React.FormEvent<HTMLFormElement>) => {
    setPasswordToucing(true);
    setHandleLoader(true);
    e.preventDefault();
    if (!inputEmailValidation && !inputPasswordVlidation) {
      setHandleLoader(false);
      return;
    }
    const userCredentials = {
      email: emailInput,
      password: passwordInput
    };

    const result = await UsersService.userSignIn(userCredentials);

    const returnToken = result?.data?.token;
    const loginEmail = result?.data?.info?.email;
    const userName = result?.data?.info?.name;
    const userID = result?.data?.info?.id;
    const role = result?.data?.info?.role;
    const vendorId = result?.data?.vendorId;
    const avatar = result?.data?.info?.avatar;
    const fiance = result?.data?.info?.partner.name;
    const referralCode = result?.data?.info?.referralCode || ``;
    const invitationsCounter = result?.data?.info?.invitationsCounter || 0;
    const userPoints = result?.data?.info?.userPoints || 0;

    if (!result?.error) {
      if (result?.data?.isEmailConfirmed === false) {
        setErrorMessage(
          'Please confirm your email to complete your login - Did not receive confirmation?'
        );
        setIsEmailConfirmed(true);
        setHandleLoader(false);
      } else {
        localStorage.setItem('vendorId', vendorId);
        localStorage.setItem('authToken', returnToken);
        localStorage.setItem('name', userName);
        localStorage.setItem('userID', userID);
        localStorage.setItem('role', role);
        localStorage.setItem('avatar', avatar);
        localStorage.setItem('email', loginEmail);
        localStorage.setItem('partner', fiance);
        localStorage.setItem('loggedIn', 'true');
        localStorage.setItem('referralCode', referralCode);
        localStorage.setItem('invitationsCounter', invitationsCounter);
        localStorage.setItem('userPoints', userPoints);

        logged = localStorage.getItem('loggedIn');
        if (
          (logged === 'true' && role === 'USER' && isTwilioVideo) ||
          (logged === 'true' && role === 'VENDOR' && isTwilioVideo)
        ) {
          window.location.href = `/${i18n.language}/twilio/${roomName}`;
        } else if (logged === 'true' && role === 'USER') {
          window.location.href = `/${i18n.language}/planner/checklist`;
        } else if (logged === 'true' && role === 'VENDOR') {
          window.location.href = `/${i18n.language}/vendor/serviceCreation`;
        }
        setAuthToken(returnToken);
        setLogin(true);
      }
    } else if (!result?.data?.status && result?.code === '4006') {
      setMarked(true);
      setErrorToken(result?.data?.token);
      setUserId(result?.data?.userId);
      setEmailToken(result?.data?.emailToken);
      localStorage.setItem('accessToken', result?.data?.token);

      setHandleLoader(false);
    } else if (
      result?.code === '4006' &&
      result?.data?.status === 'old user email'
    ) {
      setIsOldUser(true);
      setMarkedOld(true);
      setHandleLoader(false);
    } else {
      const details = result?.details;
      const chooseDetails =
        result?.details === 'Incorrect password, contact Weds360 Support.'
          ? details
          : 'Invalid Username or Password';
      setErrorMessage(chooseDetails);
      setHandleLoader(false);
    }
    setHandleLoader(false);
  };

  return (
    <>
      {handleLoader && <Loader />}
      <form onSubmit={handleLogin} className="">
        <div>
          <div>
            <input
              style={{ borderRadius: '166px' }}
              className="shadow-md w-full outline-none border-none mb-2 mt-7 p-3 lg:p-5 placeholder-black text-left transition duration-300 focus:outline-none focus:ring focus:outline-blue"
              type="text"
              placeholder={t('sign_in.sign_in_email')}
              name="usermail"
              value={emailInput}
              onChange={emailInputHandler}
            />
          </div>

          <div className="inline-flex gap-1 mb-5 justify-end">
            <span style={{ color: '#7C7C7C' }} className="text-sm ">
              {passwordValidation && (
                <span className="">
                  Please Enter a Password More Than 6 Characters.{' '}
                </span>
              )}
              {errorMessage}
              {isEmailConfirmed ? (
                <Link
                  style={{ color: '#DF8A40' }}
                  to="/en/ReceiveConfimation"
                  className="font-bold text-sm underline"
                  href="/#"
                >
                  {t('sign_in.sign_in_recieve')}
                </Link>
              ) : (
                ''
              )}
            </span>
          </div>

          <input
            style={{ borderRadius: '166px' }}
            className="shadow-md w-full w-full border-none bg-gray-100 p-3 lg:p-5 placeholder-black text-left transition duration-300 focus:outline-none focus:ring focus:border-blue-300"
            type="password"
            placeholder={t('sign_in.sign_in_pw')}
            name="userpassword"
            value={passwordInput}
            onChange={passwordInputHandler}
          />
        </div>

        <div className="text-center mt-8 sm:mb-3">
          <button
            style={{ backgroundColor: '#076A6D', borderRadius: '166px' }}
            className="w-full  p-4 text-white text-sm lg:text-2xl font-bold lg:text-3xl"
          >
            {t('sign_in.sign_in_btn')}
          </button>
          <div className="flex justify-between text-left mt-5 gap-3">
            <label
              style={{ color: '#076A6D' }}
              htmlFor="remember"
              className="font-bold"
            >
              <input
                style={{ color: '#076A6D' }}
                className="mr-1"
                type="checkbox"
                id="remember"
              />
              {t('sign_in.sign_in_remember')}
            </label>
            <Link
              style={{ color: '#076A6D' }}
              to="/en/ForgotPassword"
              className="font-bold"
              href="/#"
            >
              {t('sign_in.sign_in_fgpw')}
            </Link>
          </div>
          <div className="flex sm:hidden items-center justify-center">
            <hr className="w-4/12" />
            <p className="p-4">or</p>
            <hr className="w-4/12" />
          </div>
          <div className="w-full">
            {markedOld ? (
              <Modal isOpen={markedOld} style={customStyles}>
                <div className="p-10 ">
                  {isOldUser ? (
                    <div className="text-black text-lg mx-auto ml-12 mt-2">
                      Welcome back to Weds360! Please Check your Email reset
                      your password
                    </div>
                  ) : (
                    ``
                  )}
                  <div className="w-full flex justify-around gap-4 mt-8">
                    <Button
                      text="Discard"
                      onClickFunction={() => setMarkedOld(false)}
                      css="mb-2 lg:mb-0 text-black text-lg w-40 bg-gray-50 border-2 border-black"
                      typeofButton="reset"
                    />
                  </div>
                </div>
              </Modal>
            ) : (
              ``
            )}
          </div>
          <div className="w-full">
            {marked ? (
              <Modal isOpen={marked} style={customStyles}>
                <div className="p-10 ">
                  <h1 className="mb-5 text-center text-2xl uppercase">
                    Please insert your Email
                  </h1>
                  <div>
                    <input
                      className="w-full border-none p-3 lg:px-32 mb-3 transition duration-300 shadow-md bg-gray-100 placeholder-black text-center  focus:outline-none focus:ring focus:border-blue-300 mb-5"
                      type="email"
                      placeholder="Email"
                      name="email"
                      value={emailModalInput}
                      onChange={emailModalInputHandler}
                      required
                    />
                  </div>
                  <div className="w-full flex justify-around gap-4">
                    <Button
                      text="Discard"
                      onClickFunction={() => setMarked(false)}
                      css="mb-2 lg:mb-0 text-black text-lg w-40 bg-gray-50 border-2 border-black"
                      typeofButton="reset"
                    />
                    <Button
                      text="Submit Email"
                      onClickFunction={submitEmail}
                      css="mb-2 lg:mb-0 text-black text-lg w-40 bg-gray-50 border-2 border-black"
                      typeofButton="reset"
                    />
                  </div>
                  {isCodeMessage ? (
                    <div className="text-black-600 text-2xl mx-auto ml-2 mt-2">
                      Please check your email to get the reset password Url
                    </div>
                  ) : (
                    ''
                  )}
                  {isUserExist ? (
                    <div className="text-black-600 text-2xl mx-auto ml-2 mt-2">
                      User exist, please use another email
                    </div>
                  ) : (
                    ''
                  )}
                </div>
              </Modal>
            ) : (
              ``
            )}
          </div>
        </div>
      </form>
    </>
  );
};
