/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable @typescript-eslint/no-explicit-any */

import { useState, useEffect, useRef } from 'react';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const Participant = ({ participant }: any) => {
  const [videoTracks, setVideoTracks] = useState<any>([]);
  const [audioTracks, setAudioTracks] = useState<any>([]);
  const [width, setWidth] = useState<number>(window.innerWidth);
  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, []);
  const videoRef = useRef<HTMLVideoElement>(null);
  const audioRef = useRef<HTMLAudioElement>(null);

  const trackpubsToTracks = (trackMap: any[]) =>
    Array.from(trackMap.values())
      .map((publication) => publication.track)
      .filter((track) => track !== null);

  useEffect(() => {
    setVideoTracks(trackpubsToTracks(participant.videoTracks));
    setAudioTracks(trackpubsToTracks(participant.audioTracks));

    const isMobile: boolean = width <= 640;
    const trackSubscribed = (track: {
      kind: string;
      dimensions: { width: number; height: number };
    }) => {
      if (track.kind === 'video') {
        setVideoTracks((videoTracks: any) => [...videoTracks, track]);
      } else if (track.kind === 'audio') {
        setAudioTracks((audioTracks: any) => [...audioTracks, track]);
      }
      const videos = document.querySelectorAll('video');
      videos.forEach((video: any) => {
        video.width = isMobile
          ? window.screen.availWidth * 0.8
          : window.screen.availWidth * 0.4;
      });
    };

    const trackUnsubscribed = (track: { kind: string }) => {
      if (track.kind === 'video') {
        setVideoTracks((videoTracks: any[]) =>
          videoTracks.filter((v: { kind: string }) => v !== track)
        );
      } else if (track.kind === 'audio') {
        setAudioTracks((audioTracks: any[]) =>
          audioTracks.filter((a: { kind: string }) => a !== track)
        );
      }
    };

    participant.on('trackSubscribed', trackSubscribed);
    participant.on('trackUnsubscribed', trackUnsubscribed);

    return () => {
      setVideoTracks([]);
      setAudioTracks([]);
      participant.removeAllListeners();
    };
  }, [participant, width]);

  useEffect(() => {
    const videoTrack = videoTracks[0];
    if (videoTrack) {
      videoTrack.attach(videoRef.current);
      return () => {
        videoTrack.detach();
      };
    }
    return undefined;
  }, [videoTracks]);

  useEffect(() => {
    const audioTrack = audioTracks[0];
    if (audioTrack) {
      audioTrack.attach(audioRef.current);
      return () => {
        audioTrack.detach();
      };
    }
    return undefined;
  }, [audioTracks]);

  return (
    <div className="inline-block mr-2.5 p-1 lg:p-0 rounded-md pt-2 mx-5">
      <h3 className="text-center pb-2 text-black font-semibold text-2xl uppercase">
        {participant.identity}
      </h3>

      <video ref={videoRef} />
      <audio ref={audioRef} autoPlay />
    </div>
  );
};

export default Participant;
