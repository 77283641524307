/* eslint-disable no-unneeded-ternary */
import { useEffect, useState } from 'react';
import { FeaturedServiceGrid } from 'src/pages/ServiceDetails/ServiceFeaturedGrid';
import { VendorService } from 'src/services/vendors.service';
import { useTranslation } from 'react-i18next';
import {
  getSessionStorage,
  setSessionStorage
} from 'src/utils/sessionStorageHelper';
import { SESSION_TIER_B_KEY } from 'src/utils/storageKeys';
import { i18n } from 'src/i18n';
/* interface Tier {
  _id: string;

  featuredImage: string;
  nameEn: string;
  nameAr: string;
  descriptionEn: string;

  vendorNameEn?: string;
} */

const tierAarray = [
  {
    _id: '#',
    featuredImage: 'https://s3.eu-west-1.amazonaws.com/weds360.com/logo.png',
    nameEn: 'Advertise Here',
    nameAr: 'اعلن هنا',
    descriptionEn: '',
    vendorNameEn: 'Advertise Here',
    vendorNameAr: 'اعلن هنا'
  },
  {
    _id: '#',
    featuredImage: 'https://s3.eu-west-1.amazonaws.com/weds360.com/logo.png',
    nameEn: 'Advertise Here',
    nameAr: 'اعلن هنا',
    descriptionEn: '',
    vendorNameEn: 'Advertise Here',
    vendorNameAr: 'اعلن هنا'
  },
  {
    _id: '#',
    featuredImage: 'https://s3.eu-west-1.amazonaws.com/weds360.com/logo.png',
    nameEn: 'Advertise Here',
    nameAr: 'اعلن هنا',
    descriptionEn: '',
    vendorNameEn: 'Advertise Here',
    vendorNameAr: 'اعلن هنا'
  },
  {
    _id: '#',
    featuredImage: 'https://s3.eu-west-1.amazonaws.com/weds360.com/logo.png',
    nameEn: 'Advertise Here',
    nameAr: 'اعلن هنا',
    descriptionEn: '',
    vendorNameEn: 'Advertise Here',
    vendorNameAr: 'اعلن هنا'
  },
  {
    _id: '#',
    featuredImage: 'https://s3.eu-west-1.amazonaws.com/weds360.com/logo.png',
    nameEn: 'Advertise Here',
    nameAr: 'اعلن هنا',
    descriptionEn: '',
    vendorNameEn: 'Advertise Here',
    vendorNameAr: 'اعلن هنا'
  }
];

export const TierB = (): JSX.Element => {
  const { t } = useTranslation();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [featuredServiceTierB, setFeaturedServiceTierB] = useState<any>();
  useEffect(() => {
    const fetchData = async () => {
      if (getSessionStorage(SESSION_TIER_B_KEY) === null) {
        VendorService.getTierBFeaturedServices(`B`).then((res) => {
          setSessionStorage(SESSION_TIER_B_KEY, JSON.stringify(res));
          setFeaturedServiceTierB(res.length !== 0 ? res : tierAarray);
        });
        // todo find a way to set state using only one call
      } else {
        const data = JSON.parse(getSessionStorage(SESSION_TIER_B_KEY));
        setFeaturedServiceTierB(data.length !== 0 ? data : tierAarray);
      }
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n.language]);

  return (
    <div
      className="py-5"
      style={{
        backgroundColor: `#F6F6F6`
      }}
    >
      <div className="w-full border-0 lg:w-9/12 mx-auto mb-4" />

      <h4 className="text-2xl lg:text-4xl font-bold text-center mb-2 ">
        {t('services.new_and_notable')}
      </h4>
      <div
        className="mx-auto py-5 px-5 w-full lg:w-3/4 overflow-hidden"
        style={{
          direction: 'ltr'
        }}
      >
        {featuredServiceTierB !== undefined ? (
          <FeaturedServiceGrid services={featuredServiceTierB} />
        ) : (
          ``
        )}
      </div>
    </div>
  );
};
