const localeRegex = /^[a-z]{2}(-Han[ts]|)(-[a-z]{2}|)$/i;
export const getUrlWithNewLocale = (
  {
    pathname,
    search = '',
    hash = ''
  }: {
    pathname: string;
    search?: string;
    hash?: string;
  },
  newLocale: string
): string => {
  const [, currentLocale, ...restPath] = pathname.split('/');
  const pathRemainder = localeRegex.test(currentLocale)
    ? restPath.join('/')
    : [currentLocale, ...restPath].join('/');
  return `/${newLocale}/${pathRemainder}${hash}${search}`;
};
