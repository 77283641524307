import { Link } from 'react-router-dom';

import { useTranslation } from 'react-i18next';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper.scss';
import 'swiper/components/navigation/navigation.min.css';
import 'swiper/components/pagination/pagination.min.css';

type GridProps = {
  services: {
    _id: string;

    featuredImage: string;
    nameEn: string;
    descriptionEn: string;
    nameAr: string;
    vendorNameEn?: string;
    vendorNameAr?: string;
  }[];
};

export const FeaturedServiceGrid = ({ services }: GridProps): JSX.Element => {
  const { i18n } = useTranslation();

  return (
    <section className="text-gray-600 body-font">
      <div className="container px-5 mx-auto">
        <div className="flex flex-wrap flex-row -m-0 lg:-m-4">
          <Swiper
            className="swiper"
            autoplay={{
              delay: 2000
            }}
            loop
            centeredSlides
            watchOverflow
            autoHeight
            setWrapperSize
            breakpoints={{
              319: {
                width: 319,
                slidesPerView: 2
              },
              640: {
                width: 640,
                slidesPerView: 3
              },
              768: {
                width: 768,
                slidesPerView: 5,
                spaceBetween: 10
              }
            }}
          >
            {services?.map((service) => (
              <>
                <SwiperSlide
                  key={service?._id}
                  className="flex items-center justify-center h-auto w-full mb-8"
                >
                  <div className="p-4 hover:pointer ">
                    <Link
                      to={`/${i18n.language}/service/${service?._id}/${
                        i18n.language === 'en'
                          ? service?.nameEn
                              .toLocaleLowerCase()
                              .split(' ')
                              .join('-')
                          : service?.nameAr
                              .toLocaleLowerCase()
                              .split(' ')
                              .join('-')
                      }`}
                      target="_blank"
                    >
                      <div
                        className="h-48 border-2 border-gray-200 border-opacity-60 rounded-lg overflow-hidden shadow-md hover:shadow-lg"
                        style={{ width: `150px` }}
                      >
                        <img
                          className="h-32  w-full object-cover object-center"
                          src={service?.featuredImage}
                          alt={
                            i18n.language === 'en'
                              ? service?.nameEn
                              : service?.nameAr
                          }
                        />
                        <div className="p-2">
                          <h2 className="tracking-widest text-xs title-font font-medium text-gray-400 mb-1">
                            {i18n.language === 'en'
                              ? service?.vendorNameEn
                              : service?.vendorNameAr}
                          </h2>
                          <h1 className="title-font text-xs font-medium text-gray-900">
                            {i18n.language === 'en'
                              ? service?.nameEn
                              : service?.nameAr}
                          </h1>
                        </div>
                      </div>
                    </Link>
                  </div>
                </SwiperSlide>
              </>
            ))}
          </Swiper>
        </div>
      </div>
    </section>
  );
};
