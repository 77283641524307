/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/self-closing-comp */
/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-restricted-globals */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-boolean-value */
import { useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { useRecoilState } from 'recoil';
import { MenuIcon, XIcon } from '@heroicons/react/outline';
import { i18n } from 'src/i18n';
import { getLocalStorage, setLocalStorage } from 'src/utils/localStorageHelper';
import { LOCAL_HER, LOCAL_HIM, LOCAL_WEDDING } from 'src/utils/storageKeys';
import { getUrlWithNewLocale } from 'src/utils/navigation';
import { classNames } from 'src/utils/tailwind';
import axios from 'axios';
import { Steps } from 'intro.js-react';
import 'intro.js/introjs.css';
import { BASE_URL } from 'src/config/app.config';
import { UsersService } from 'src/services/users.service';
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel
} from 'react-accessible-accordion';

import { CategoryService } from '../../services/category.service';
import '@themesberg/flowbite';

import {
  LoggedInState,
  authTokenState
} from '../../recoilResources/User.Atoms';

import './index.css';

type GridProps = {
  isTextBlack?: boolean;
};

const avatar: any = localStorage.getItem(`avatar`);
const userName: any = localStorage.getItem(`name`);
const userEmail: any = localStorage.getItem(`email`);
const role: any = localStorage.getItem(`role`);
const userPoints: any = localStorage.getItem(`userPoints`);

const steps = [
  {
    element: '.step1',
    intro: `${
      i18n.language === `en`
        ? `Congratulations! Ready to take the first step towards your new life? At Weds360 we are here to help you make wedding planning a piece of cake every step of the way. `
        : `مبروك! جاهز تاخد أول خطوة في حياتك الجديدة؟ الترتيب لفرحك أسهل كتير مع Weds360. لأننا عارفين إيه المهم، تقدر تعتمد علينا في كل تفصيلة.`
    }`,
    title: `${
      i18n.language === `en`
        ? ` <img src="https://weds360.com/favicon.png" alt=" className="w-4 h-4"/><p id="pEnglish">Tour Weds360</p>`
        : `<img src="https://weds360.com/favicon.png" alt=" className="w-4 h-4" id="arabicImage"/><p id="pArabic">الموقع في ١٠ ثواني</p>`
    }`
  },
  {
    element: '.step2',
    intro: `${
      i18n.language === `en`
        ? `Get the 360 view of your wedding planning needs with our all-in-one website. You’ll find everything from engagement announcements to post-wedding life. We made wedding planning stress-free for you. `
        : `شوف تصور فرحك 360 درجة. كل اللي هتحتاجه في رحلة التخطيط للفرح موجود، كل حاجة فكرت فيها أو ما فكرتش! من أول الخطوبة لغاية بعد الفرح. معانا هتنسى التوتر وإنت بتخطط لفرحك`
    }`,
    title: `${
      i18n.language === `en`
        ? ` <img src="https://weds360.com/favicon.png" alt=" className="w-4 h-4"/><p id="pEnglish">Tour Weds360</p>`
        : `<img src="https://weds360.com/favicon.png" alt=" className="w-4 h-4" id="arabicImage"/><p id="pArabic">الموقع في ١٠ ثواني</p>`
    }`
  },
  {
    element: '.step3',
    intro: `${
      i18n.language === `en`
        ? `We’re just as excited as you! Make-up artists, designers and every girly thing you’ll need is here to beautify you inside out.`
        : `متحمسين زيك بالظبط، من تصميم فستان الفرح لغاية الميكب أرتست وأي حاجة هتفكري فيها موجودة هنا`
    }`,
    title: `${
      i18n.language === `en`
        ? ` <img src="https://weds360.com/favicon.png" alt=" className="w-4 h-4"/><p id="pEnglish">Tour Weds360</p>`
        : `<img src="https://weds360.com/favicon.png" alt=" className="w-4 h-4" id="arabicImage"/><p id="pArabic">الموقع في ١٠ ثواني</p>`
    }`
  },
  {
    element: '.step4',
    intro: `${
      i18n.language === `en`
        ? `We didn’t forget about the handsome groom! We’ve got you covered with helpful advice that will have you confident as ever in your wedding. `
        : `عاملين حسابك.. كل حاجة ممكن تفكر فيها هتلاقيها هنا، مدورش كتير!`
    }`,
    title: `${
      i18n.language === `en`
        ? ` <img src="https://weds360.com/favicon.png" alt=" className="w-4 h-4"/><p id="pEnglish">Tour Weds360</p>`
        : `<img src="https://weds360.com/favicon.png" alt=" className="w-4 h-4" id="arabicImage"/><p id="pArabic">الموقع في ١٠ ثواني</p>`
    }`
  },
  {
    element: '.step5',
    intro: `${
      i18n.language === `en`
        ? `All your wedding needs and concerns tucked in a single section. Everything from stunning venues to cutting edge videographers awaits you here. Don’t miss a single detail on your big day!`
        : `كل اللي محتاجه للفرح هتلاقيه في مكان واحد، من أماكن الأفراح لغاية مصورين الفيديو، علشان متنساش حاجة.. كله هنا`
    }`,
    title: `${
      i18n.language === `en`
        ? ` <img src="https://weds360.com/favicon.png" alt=" className="w-4 h-4"/><p id="pEnglish">Tour Weds360</p>`
        : `<img src="https://weds360.com/favicon.png" alt=" className="w-4 h-4" id="arabicImage"/><p id="pArabic">الموقع في ١٠ ثواني</p>`
    }`
  },
  {
    element: '.step6',
    intro: `${
      i18n.language === `en`
        ? `Join our vendor list where we made matching clients a click away. Sign up to expand your brand’s horizon.`
        : `إنضم لقايمة أفضل متخصصين الأفراح، بخطوة واحدة هتنشر البراند!`
    }`,
    title: `${
      i18n.language === `en`
        ? ` <img src="https://weds360.com/favicon.png" alt=" className="w-4 h-4"/><p id="pEnglish">Tour Weds360</p>`
        : `<img src="https://weds360.com/favicon.png" alt=" className="w-4 h-4" id="arabicImage"/><p id="pArabic">الموقع في ١٠ ثواني</p>`
    }`
  },
  {
    element: '.step7',
    intro: `${
      i18n.language === `en`
        ? `In wedding planning, a picture is really worth a thousand words, check our gallery for the latest real life wedding inspirations to spark your creativity. `
        : `مش عارف إيه الجديد في عالم الأفراح! هنا صور لكل الأفكار الجديدة من أول الديكور لغاية الفوتوسيشن.. كله على الطبيعة`
    }`,
    title: `${
      i18n.language === `en`
        ? ` <img src="https://weds360.com/favicon.png" alt=" className="w-4 h-4"/><p id="pEnglish">Tour Weds360</p>`
        : `<img src="https://weds360.com/favicon.png" alt=" className="w-4 h-4" id="arabicImage"/><p id="pArabic">الموقع في ١٠ ثواني</p>`
    }`
  },
  {
    element: '.step8',
    intro: `${
      i18n.language === `en`
        ? `Look for quick tips in our article library that carries pages of helpful advice. Read through for endless wedding inspiration and planning tips.`
        : `عايز نصيحة سريعة! هتلاقي كتير من النصايح، الأفكار والخطوات اللي هتفيدك في ترتيب الفرح`
    }`,
    title: `${
      i18n.language === `en`
        ? ` <img src="https://weds360.com/favicon.png" alt=" className="w-4 h-4"/><p id="pEnglish">Tour Weds360</p>`
        : `<img src="https://weds360.com/favicon.png" alt=" className="w-4 h-4" id="arabicImage"/><p id="pArabic">الموقع في ١٠ ثواني</p>`
    }`
  },
  {
    element: '.step9',
    intro: `${
      i18n.language === `en`
        ? `Find about our main services here.`
        : `اتعرف على الموقع من هنا`
    }`,
    title: `${
      i18n.language === `en`
        ? ` <img src="https://weds360.com/favicon.png" alt=" className="w-4 h-4"/><p id="pEnglish">Tour Weds360</p>`
        : `<img src="https://weds360.com/favicon.png" alt=" className="w-4 h-4" id="arabicImage"/><p id="pArabic">الموقع في ١٠ ثواني</p>`
    }`
  },
  {
    element: '.step10',
    intro: `${
      i18n.language === `en` ? `You can search here` : `تقدر تدور من هنا`
    }`,
    title: `${
      i18n.language === `en`
        ? ` <img src="https://weds360.com/favicon.png" alt=" className="w-4 h-4"/><p id="pEnglish">Tour Weds360</p>`
        : `<img src="https://weds360.com/favicon.png" alt=" className="w-4 h-4" id="arabicImage"/><p id="pArabic">الموقع في ١٠ ثواني</p>`
    }`
  },
  {
    element: '.step11',
    intro: `${
      i18n.language === `en`
        ? `you can join us here`
        : `إعمل حسابك الشخصي من هنا`
    }`,
    title: `${
      i18n.language === `en`
        ? ` <img src="https://weds360.com/favicon.png" alt=" className="w-4 h-4"/><p id="pEnglish">Tour Weds360</p>`
        : `<img src="https://weds360.com/favicon.png" alt=" className="w-4 h-4" id="arabicImage"/><p id="pArabic">الموقع في ١٠ ثواني</p>`
    }`
  },
  {
    element: '.step12',
    intro: `${
      i18n.language === `en`
        ? `you can change language here`
        : `تقدر تغير اللغة من هنا`
    }`,
    title: `${
      i18n.language === `en`
        ? ` <img src="https://weds360.com/favicon.png" alt=" className="w-4 h-4"/><p id="pEnglish">Tour Weds360</p>`
        : `<img src="https://weds360.com/favicon.png" alt=" className="w-4 h-4" id="arabicImage"/><p id="pArabic">الموقع في ١٠ ثواني</p>`
    }`
  },
  {
    element: '.step13',
    intro: `${
      i18n.language === `en`
        ? `Your happily ever after starts here, happy planning!`
        : `بدايتك الحقيقية تبتدي من Weds360`
    }`,
    title: `${
      i18n.language === `en`
        ? ` <img src="https://weds360.com/favicon.png" alt=" className="w-4 h-4"/><p id="pEnglish">Tour Weds360</p>`
        : `<img src="https://weds360.com/favicon.png" alt=" className="w-4 h-4" id="arabicImage"/><p id="pArabic">الموقع في ١٠ ثواني</p>`
    }`
  }
];

const DropDownMenu = styled.ul`
  display: hidden;
`;
const DropDown = styled.div`
  &:hover {
    ${DropDownMenu} {
      display: block;
    }
  }
`;
interface Category {
  nameEn: string;
  nameAr: string;
  _id: string;
}

export const NewNavbar = (isTextBlack: GridProps): JSX.Element => {
  const { t } = useTranslation();
  const history = useHistory();

  const [scrolled, setScrolled] = useState(false);

  const [categoriesForHim, setCategoriesForHim] = useState<Category[]>();
  const [categoriesForHer, setCategoriesForHer] = useState<Category[]>();
  const [categoriesForWedding, setCategoriesForWedding] =
    useState<Category[]>();
  const [auth, authToken] = useRecoilState(authTokenState);
  const [login, setLogin] = useRecoilState(LoggedInState);
  const [hideSearchDropdown, sitHideSearchDropdown] = useState(false);
  const [appeardropDownSearchData, setAppeardropDownSearchData] =
    useState(false);
  const [error, setError] = useState(false);
  const [errorSearch, setErrorSearch] = useState('');
  const [searchBarKey, setSearchBarKey] = useState('');
  const [showMegaMenu, setShowMegaMenu] = useState(false);
  const [categsData, setCategsData] = useState<any>();
  const [serviceResult, setServiceResult] = useState<any>();
  const [serviceByCateg, setServiceByCateg] = useState<any>();
  const [block, setBlock] = useState(false);
  const [userMenu, setUserMenu] = useState(false);
  const [stepsEnabled, setStepsEnabled] = useState(true);

  useEffect(() => {
    const item = auth;

    if (item !== '') {
      setLogin(true);
    }
  }, [auth, setLogin]);

  useEffect(() => {
    const updateScroll = () => {
      const scrollY = window.pageYOffset;
      if (scrollY > 0) {
        setScrolled(true);
      }
      if (scrollY === 0) {
        setScrolled(false);
      }
    };

    window.addEventListener('scroll', updateScroll);
    const fetchData = async () => {
      if (localStorage.getItem(`firstVisit`) === null) {
        setTimeout(() => {
          localStorage.setItem(`firstVisit`, `true`);
        }, 3000);
      }

      if (
        getLocalStorage(LOCAL_HIM) === null ||
        getLocalStorage(LOCAL_HER) === null ||
        getLocalStorage(LOCAL_WEDDING) === null
      ) {
        CategoryService.getCategoryForHim().then((res) => {
          setLocalStorage(LOCAL_HIM, JSON.stringify(res));
          setCategoriesForHim(res);
        });
        CategoryService.getCategoryForHer().then((res) => {
          setLocalStorage(LOCAL_HER, JSON.stringify(res));
          setCategoriesForHer(res);
        });
        CategoryService.getCategoryForWedding().then((res) => {
          setLocalStorage(LOCAL_WEDDING, JSON.stringify(res));
          setCategoriesForWedding(res);
        });
      } else {
        setCategoriesForHim(JSON.parse(getLocalStorage(LOCAL_HIM)));
        setCategoriesForHer(JSON.parse(getLocalStorage(LOCAL_HER)));
        setCategoriesForWedding(JSON.parse(getLocalStorage(LOCAL_WEDDING)));
      }
    };
    fetchData();
  }, [scrolled]);

  const navigation = [
    {
      name: t('common.360_planner'),
      href: `/${i18n.language}/360Planner`,
      step: `step2`,
      list: [
        {
          name: t('common.check_list'),
          href: `${
            role === 'USER'
              ? `/${i18n.language}/planner/checklist`
              : `/${i18n.language}/360Planner/checklist`
          }`
        },
        {
          name: t('common.budgeter'),
          href: `${
            role === 'USER'
              ? `/${i18n.language}/planner/budgeter`
              : `/${i18n.language}/360Planner/budgeter`
          }`
        },
        {
          name: t('common.registry_list'),
          href: `${
            role === 'USER'
              ? `/${i18n.language}/planner/registrylist`
              : `/${i18n.language}/360Planner/registrylist`
          }`
        },
        {
          name: t('common.guest_list'),
          href: `${
            role === 'USER'
              ? `/${i18n.language}/planner/guestlist`
              : `/${i18n.language}/360Planner/guestlist`
          }`
        },
        {
          name: t('common.wedding_website'),
          href: `${
            role === 'USER'
              ? `/${i18n.language}/planner/weddingWebsite`
              : `/${i18n.language}/Login`
          }`
        }
        // {
        //   name: 'Likes',
        //   href: `${
        //     role === 'USER'
        //       ? `/${i18n.language}/planner/likes`
        //       : `/${i18n.language}/SignUp`
        //   }`
        // }
      ]
    },
    {
      name: t('common.her'),
      step: `step3`,
      href: `/${i18n.language}/services`,
      list: [
        {
          name: `${
            categoriesForHer
              ? i18n.language === 'en'
                ? categoriesForHer[0]?.nameEn
                : categoriesForHer[0]?.nameAr
              : ``
          }`,
          href: `/${i18n.language}/services/category/${
            categoriesForHer ? categoriesForHer[0]?._id : ``
          }`
        },
        {
          name: `${
            categoriesForHer
              ? i18n.language === 'en'
                ? categoriesForHer[1]?.nameEn
                : categoriesForHer[1]?.nameAr
              : ``
          }`,
          href: `/${i18n.language}/services/category/${
            categoriesForHer ? categoriesForHer[1]?._id : ``
          }`
        },
        {
          name: `${
            categoriesForHer
              ? i18n.language === 'en'
                ? categoriesForHer[2]?.nameEn
                : categoriesForHer[2]?.nameAr
              : ``
          }`,
          href: `/${i18n.language}/services/category/${
            categoriesForHer ? categoriesForHer[2]?._id : ``
          }`
        },
        {
          name: `${
            categoriesForHer
              ? i18n.language === 'en'
                ? categoriesForHer[3]?.nameEn
                : categoriesForHer[3]?.nameAr
              : ``
          }`,
          href: `/${i18n.language}/services/category/${
            categoriesForHer ? categoriesForHer[3]?._id : ``
          }`
        },
        {
          name: `${
            categoriesForHer
              ? i18n.language === 'en'
                ? categoriesForHer[4]?.nameEn
                : categoriesForHer[4]?.nameAr
              : ``
          }`,
          href: `/${i18n.language}/services/category/${
            categoriesForHer ? categoriesForHer[4]?._id : ``
          }`
        },
        { name: t('common.More'), href: `/${i18n.language}/moreHer/` }
      ]
    },
    {
      name: t('common.him'),
      step: `step4`,
      href: `/${i18n.language}/services`,
      list: [
        {
          name: `${
            categoriesForHim
              ? i18n.language === 'en'
                ? categoriesForHim[0]?.nameEn
                : categoriesForHim[0]?.nameAr
              : ``
          }`,
          href: `/${i18n.language}/services/category/${
            categoriesForHim ? categoriesForHim[0]?._id : ``
          }`
        },
        {
          name: `${
            categoriesForHim
              ? i18n.language === 'en'
                ? categoriesForHim[1]?.nameEn
                : categoriesForHim[1]?.nameAr
              : ``
          }`,
          href: `/${i18n.language}/services/category/${
            categoriesForHim ? categoriesForHim[1]?._id : ``
          }`
        },
        {
          name: `${
            categoriesForHim
              ? i18n.language === 'en'
                ? categoriesForHim[2]?.nameEn
                : categoriesForHim[2]?.nameAr
              : ``
          }`,
          href: `/${i18n.language}/services/category/${
            categoriesForHim ? categoriesForHim[2]?._id : ``
          }`
        },
        {
          name: `${
            categoriesForHim
              ? i18n.language === 'en'
                ? categoriesForHim[3]?.nameEn
                : categoriesForHim[3]?.nameAr
              : ``
          }`,
          href: `/${i18n.language}/services/category/${
            categoriesForHim ? categoriesForHim[3]?._id : ``
          }`
        },
        { name: t('common.More'), href: `/${i18n.language}/moreHim/` }
      ]
    },
    {
      name: t('common.the_wedding'),
      step: `step5`,
      href: `/${i18n.language}/services`,
      list: [
        {
          name: `${
            categoriesForWedding
              ? i18n.language === 'en'
                ? categoriesForWedding[0]?.nameEn
                : categoriesForWedding[0]?.nameAr
              : ``
          }`,
          href: `/${i18n.language}/services/category/${
            categoriesForWedding ? categoriesForWedding[0]?._id : ``
          }`
        },
        {
          name: `${
            categoriesForWedding
              ? i18n.language === 'en'
                ? categoriesForWedding[1]?.nameEn
                : categoriesForWedding[1]?.nameAr
              : ``
          }`,
          href: `/${i18n.language}/services/category/${
            categoriesForWedding ? categoriesForWedding[1]?._id : ``
          }`
        },
        {
          name: `${
            categoriesForWedding
              ? i18n.language === 'en'
                ? categoriesForWedding[2]?.nameEn
                : categoriesForWedding[2]?.nameAr
              : ``
          }`,
          href: `/${i18n.language}/services/category/${
            categoriesForWedding ? categoriesForWedding[2]?._id : ``
          }`
        },
        {
          name: `${
            categoriesForWedding
              ? i18n.language === 'en'
                ? categoriesForWedding[3]?.nameEn
                : categoriesForWedding[3]?.nameAr
              : ``
          }`,
          href: `/${i18n.language}/services/category/${
            categoriesForWedding ? categoriesForWedding[3]?._id : ``
          }`
        },
        {
          name: `${
            categoriesForWedding
              ? i18n.language === 'en'
                ? categoriesForWedding[4]?.nameEn
                : categoriesForWedding[4]?.nameAr
              : ``
          }`,
          href: `/${i18n.language}/services/category/${
            categoriesForWedding ? categoriesForWedding[4]?._id : ``
          }`
        },
        { name: t('common.More'), href: `/${i18n.language}/moreWeddings/` }
      ]
    },
    {
      name: t('common.vendors'),
      step: `step6`,
      href: `/${i18n.language}/vendors`,
      list:
        role === `VENDOR`
          ? [
              {
                name: t('vendors.profile_details'),
                href: `/${i18n.language}/vendor/updateInfo`
              },
              {
                name: t('vendors.service_list'),
                href: `/${i18n.language}/vendorList`
              },
              {
                name: t('common.subscription'),
                href: `/${i18n.language}/vendor/subscription`
              }
              // {
              //   name: 'Likes',
              //   href: `/${i18n.language}/vendor/likes`
              // }
            ]
          : [
              {
                name: t('common.createVendorsList'),
                href: `/${i18n.language}/SignUp`
              }
            ]
    },
    {
      name: t('common.gallery'),
      step: `step7`,
      href: `/${i18n.language}/gallery`,
      list: [
        { name: t('common.WeddingIdeas'), href: `/${i18n.language}/photos` }
      ]
    },
    {
      name: t('common.ideas_and_more'),
      step: `step8`,
      href: `/${i18n.language}/articles`,
      list: [
        {
          name: t('common.Tips_and_Etiquette'),
          href: `/${i18n.language}/TipsAndAdvice`
        },
        {
          name: t('common.Wedding_of_the_Week'),
          href: `/${i18n.language}/WeddingOfTheWeek`
        }
      ]
    },
    {
      name: t('common.take_a_tour'),
      step: `step9`,
      href: ``,
      list: []
    }
  ];

  const handleUserNavigation = (item: any) => {
    localStorage.removeItem('role');
    localStorage.removeItem('authToken');
    localStorage.removeItem('vendorId');
    localStorage.removeItem('email');
    localStorage.removeItem('name');
    localStorage.removeItem('userID');
    localStorage.removeItem('avatar');
    localStorage.removeItem('partner');
    localStorage.removeItem('loggedIn');
    localStorage.removeItem('referralCode');
    localStorage.removeItem('invitationsCounter');
    if (
      window.location.href.includes('/planner') ||
      window.location.href.includes('/vendor') ||
      window.location.href.includes('/user') ||
      window.location.href.includes('/payment')
    ) {
      window.location.replace(window.location.origin);
    } else {
      window.location.reload();
    }
  };

  const handleSubmitSearchBar = async (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setSearchBarKey(e.target.value);
    setShowMegaMenu(false);
    sitHideSearchDropdown(false);
    e.preventDefault();

    if (searchBarKey.trim().length === 0) {
      setErrorSearch('Please choose or type a field');
      setError(true);
    }

    let url = `${BASE_URL}find/search?parentMenu=service&`;
    const params = new URLSearchParams();

    params.append('key', `${e.target.value}`);

    url += params.toString();
    const searchURL = `${BASE_URL}find/recommendation?key=${e.target.value}`;
    const styling =
      i18n.language === 'en' ? 'flex-row gap-2' : 'flex-row-reverse gap-2';

    const getCategoriesData = () => {
      axios.get(searchURL).then((res) => {
        const categoryResult = res?.data?.data?.categoryResult;
        const resultsCategData = categoryResult.map((item: any) => {
          return (
            <div>
              <Link
                to={`/${i18n.language}/services/category/${item._id}`}
                className={`p-1 hover:bg-gray-300 inline-flex ${styling} items-center justify-start w-full`}
              >
                {i18n.language === 'en' ? item.nameEn : item.nameAr}
                <p className="text-gray-600 text-lg">{t('common.incateg')}</p>
              </Link>
            </div>
          );
        });
        setCategsData(resultsCategData);

        const serviceByResult = res?.data?.data?.serviceResult;

        const serviceResults = serviceByResult.map((item: any) => {
          return (
            <>
              <Link
                to={`/${i18n.language}/service/${item._id}/${
                  i18n.language === 'en'
                    ? item?.nameEn.toLocaleLowerCase().split(' ').join('-')
                    : item?.nameAr.toLocaleLowerCase().split(' ').join('-')
                }`}
                className="block hover:bg-gray-300 p-1 w-full"
              >
                {i18n.language === 'en' ? item.nameEn : item.nameAr}
              </Link>
            </>
          );
        });

        setServiceResult(serviceResults);

        const serviceByCategory = res?.data?.data?.serviceByCategory;

        const servicesByCategory = Object.entries(serviceByCategory);

        const servicessByCategory = servicesByCategory.map((item: any) => {
          const lastItem = item[1].map((ele: any) => {
            return (
              <>
                <Link
                  to={`/${i18n.language}/service/${ele.serviceId}/${
                    i18n.language === 'en'
                      ? ele?.serviceNameEn
                          .toLocaleLowerCase()
                          .split(' ')
                          .join('-')
                      : ele?.serviceNameAr
                          .toLocaleLowerCase()
                          .split(' ')
                          .join('-')
                  }`}
                  className={`p-1 w-full hover:bg-gray-300 flex ${styling} items-center justify-between`}
                >
                  {i18n.language === 'en'
                    ? ele.serviceNameEn
                    : ele.serviceNameAr}
                  <p className="text-gray-600 text-lg">{item[0]}</p>
                </Link>
              </>
            );
          });

          return lastItem;
        });

        setServiceByCateg(servicessByCategory);
      });
    };
    getCategoriesData();
    const result: any = await UsersService.searchHome(url);
    setAppeardropDownSearchData(true);

    const datas = result?.serviceSearchResult;

    let resultsArray: any = [];
    if (datas?.photos) {
      // eslint-disable-next-line func-names
      Object.keys(datas).forEach(function (key) {
        if (key === 'services') {
          const servicesArr = datas.services.map((service: any) => {
            return (
              <>
                <Link
                  to={`/${i18n.language}/service/${service._id}/${
                    i18n.language === 'en'
                      ? service?.nameEn.toLocaleLowerCase().split(' ').join('-')
                      : service?.nameAr.toLocaleLowerCase().split(' ').join('-')
                  }`}
                  className="block w-full hover:bg-gray-300 p-1 "
                >
                  {i18n.language === 'en' ? service.nameEn : service.nameAr}
                </Link>
              </>
            );
          });
          resultsArray = [...resultsArray, ...servicesArr];
        }
        if (key === 'photos') {
          const photosArr = datas.photos.map((photo: any) => {
            return (
              <>
                <Link
                  to={`/${i18n.language}/photo/${photo._id}/${photo.titleEn
                    .toLocaleLowerCase()
                    .split(' ')
                    .join('-')}`}
                  className="block w-full hover:bg-gray-300 p-1 "
                >
                  {i18n.language === 'en' ? photo.titleEn : photo.titleAr}
                </Link>
              </>
            );
          });
          resultsArray = [...resultsArray, ...photosArr];
        }
        if (key === 'posts') {
          const postsArr = datas.posts.map((post: any) => {
            return (
              <>
                <Link
                  to={`/${i18n.language}/TipsAndAdvice/${
                    post._id
                  }/${post.titleEn.toLocaleLowerCase().split(' ').join('-')}`}
                  className="block w-full hover:bg-gray-300 p-1 "
                >
                  {i18n.language === 'en' ? post.titleEn : post.titleAr}
                </Link>
              </>
            );
          });
          resultsArray = [...resultsArray, ...postsArr];
        }
      });
      setAppeardropDownSearchData(true);
      setErrorSearch('');
    }
  };

  const toggleBLock = () => {
    setBlock(!block);
  };

  const toggleUserMenu = () => {
    setUserMenu(!userMenu);
  };

  const options = {
    hidePrev: true,
    showBullets: false,
    showProgress: true,
    nextLabel: ` ${i18n.language === `en` ? 'Next' : 'بعده'}`,
    skipLabel: `X`,
    prevLabel: ` ${i18n.language === `en` ? 'Previous' : 'قبله'}`,
    doneLabel: ` ${i18n.language === `en` ? 'Done' : 'تم'}`
  };

  return (
    <div>
      {localStorage.getItem(`firstVisit`) === null && innerWidth >= 768 ? (
        <Steps
          enabled={stepsEnabled}
          options={options}
          steps={steps}
          initialStep={0}
          onExit={() => setStepsEnabled(false)}
        />
      ) : (
        ``
      )}
      <nav
        className="bg-white px-2 sm:px-4 py-2.5 dark:bg-gray-800 relative"
        style={{
          borderBottomLeftRadius: innerWidth < 768 ? '25px' : '50px',
          borderBottomRightRadius: innerWidth < 768 ? '25px' : '50px',
          boxShadow: `0px -22px 54px rgba(0, 0, 0, 0.15)`,
          backgroundColor: userMenu && innerWidth < 768 ? `#EBEBEB` : `white`
        }}
      >
        <div className="container flex flex-wrap items-center mx-auto lg:w-11/12">
          <Link to={`/${i18n.language}`} className="flex">
            <span className="self-center text-lg font-semibold whitespace-nowrap dark:text-white md:mt-5">
              <img
                className="h-10 xl:h-16 w-auto 2xl:mr-20"
                src="https://s3.eu-west-1.amazonaws.com/weds360.com/logo.png"
                alt="Workflow"
                style={{
                  marginLeft: i18n.language === 'en' ? '10px' : ``,
                  marginRight: i18n.language === 'ar' ? '10px' : ``
                }}
              />
            </span>
          </Link>
          <div
            className={
              block
                ? 'flex lg:block items-center md:order-3 mx-auto relative'
                : 'flex items-center md:order-3 mx-auto'
            }
          >
            <div
              className="block sm:hidden md:mr-0 ml-1"
              style={{
                width:
                  innerWidth < 768 && innerWidth > 375
                    ? `200px`
                    : innerWidth < 375
                    ? `150px`
                    : `400px`
              }}
            >
              {i18n.language === `en` ? (
                <div className="hidden sm:flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
                  <svg
                    className="w-5 h-5 text-gray-500"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                      clipRule="evenodd"
                    ></path>
                  </svg>
                </div>
              ) : (
                ``
              )}

              <button
                className={classNames(
                  localStorage.getItem('i18nextLng') !== 'en' ? 'ml-3' : 'mr-3',
                  'block sm:hidden'
                )}
              >
                <Link to={`/${i18n.language}/search`}>
                  <svg
                    width="22"
                    height="22"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M14.2197 15.2803C14.5126 15.5732 14.9874 15.5732 15.2803 15.2803C15.5732 14.9874 15.5732 14.5126 15.2803 14.2197L14.2197 15.2803ZM12.0178 10.9572C11.7249 10.6643 11.2501 10.6643 10.9572 10.9572C10.6643 11.2501 10.6643 11.7249 10.9572 12.0178L12.0178 10.9572ZM12.5 7.25C12.5 10.1495 10.1495 12.5 7.25 12.5V14C10.9779 14 14 10.9779 14 7.25H12.5ZM7.25 12.5C4.3505 12.5 2 10.1495 2 7.25H0.5C0.5 10.9779 3.52208 14 7.25 14V12.5ZM2 7.25C2 4.3505 4.3505 2 7.25 2V0.5C3.52208 0.5 0.5 3.52208 0.5 7.25H2ZM7.25 2C10.1495 2 12.5 4.3505 12.5 7.25H14C14 3.52208 10.9779 0.5 7.25 0.5V2ZM15.2803 14.2197L12.0178 10.9572L10.9572 12.0178L14.2197 15.2803L15.2803 14.2197Z"
                      fill="#1A1A1A"
                    />
                  </svg>
                </Link>
              </button>

              <input
                onChange={handleSubmitSearchBar}
                onBlur={() =>
                  setTimeout(() => {
                    sitHideSearchDropdown(true);
                  }, 100)
                }
                type="text"
                id="email-adress-icon"
                className="hidden sm:block p-2 pl-10 w-11/12 text-gray-900 bg-gray-50 rounded-lg border border-gray-300 sm:text-sm "
                placeholder={`${t('actions.search')}`}
              />
              {appeardropDownSearchData && (
                <div
                  className={`${
                    hideSearchDropdown
                      ? 'hidden'
                      : ' text-left bg-white h-40 mt-1 p-2 overflow-y-scroll max-h-60 w-72 overflow-y-auto absolute z-50'
                  }`}
                >
                  {categsData}
                  {serviceResult}
                  {serviceByCateg}
                </div>
              )}
            </div>

            {innerWidth < 768 ? (
              <button
                className="flex text-sm rounded-full md:mr-0"
                id="user-menu-button"
                onClick={toggleUserMenu}
              >
                <span className="sr-only">Open user menu</span>
                <span className="hidden sm:block">
                  {!login ? (
                    <Link to={`/${i18n.language}/Login`}>
                      <i className="fas fa-user-plus" />
                    </Link>
                  ) : (
                    <>
                      <img
                        className="h-6 w-6 rounded-full"
                        src={avatar}
                        alt=""
                      />
                    </>
                  )}
                  <i className="mx-1" />
                </span>
                <button
                  className={classNames(
                    localStorage.getItem('i18nextLng') !== 'en'
                      ? 'mr-7'
                      : 'ml-7',
                    'absolute flex items-center mx-2 text-sm bg-transparent rounded-full text-gray-600 hover:text-gray-500'
                  )}
                  onClick={() => {
                    i18n.changeLanguage(i18n.language === 'en' ? 'ar' : 'en');
                    window.localStorage.setItem('i18nextLng', i18n.language);
                    const url = getUrlWithNewLocale(
                      history.location,
                      i18n.language
                    );
                    history.replace(url, history.location.state);
                    window.location.reload();
                  }}
                  style={{
                    color: `#024D4C`,
                    marginTop: `-11px`
                  }}
                >
                  <i className="mx-1" />
                  <span
                    className="bg-black rounded font-semibold text-white text-xs"
                    style={{
                      paddingTop: i18n.language === 'en' ? `` : `5px`,
                      paddingBottom: i18n.language === 'en' ? `5px` : ``,
                      paddingRight: `4px`,
                      paddingLeft: `4px`
                    }}
                  >
                    {i18n.language === 'en' ? 'ع' : 'En'}{' '}
                  </span>
                </button>
              </button>
            ) : (
              <button
                className="flex text-sm rounded-full md:mr-0 disabled"
                id="user-menu-button"
                onClick={toggleBLock}
              >
                <span className="sr-only">Open user menu</span>
                {!login ? (
                  <Link to={`/${i18n.language}/Login`}>
                    <i className="fas fa-user-plus step11" />
                  </Link>
                ) : (
                  <>
                    <img className="h-6 w-6 rounded-full" src={avatar} alt="" />
                  </>
                )}
                <i className="mx-1" />

                <button
                  className={classNames(
                    localStorage.getItem('i18nextLng') !== 'en'
                      ? 'mr-7'
                      : 'ml-7',
                    'absolute flex items-center mx-2 text-sm bg-transparent rounded-full text-gray-600 hover:text-gray-500'
                  )}
                  onClick={() => {
                    i18n.changeLanguage(i18n.language === 'en' ? 'ar' : 'en');
                    window.localStorage.setItem('i18nextLng', i18n.language);
                    const url = getUrlWithNewLocale(
                      history.location,
                      i18n.language
                    );
                    history.replace(url, history.location.state);
                    window.location.reload();
                  }}
                  style={{
                    color: `
                  #024D4C`
                  }}
                >
                  <i className="mx-1" />
                  <span
                    className="bg-black rounded font-semibold text-white text-xs step12"
                    style={{
                      paddingTop: i18n.language === 'en' ? `` : `5px`,
                      paddingBottom: i18n.language === 'en' ? `5px` : ``,
                      paddingRight: `4px`,
                      paddingLeft: `4px`
                    }}
                  >
                    {i18n.language === 'en' ? 'ع' : 'En'}{' '}
                  </span>
                </button>
              </button>
            )}

            <div
              className={
                block
                  ? 'block z-50 my-4 text-base list-none bg-white rounded divide-y divide-gray-100 absolute '
                  : 'hidden z-50 my-4 text-base list-none bg-white rounded divide-y divide-gray-100'
              }
              id={
                i18n.language === `en` && innerWidth >= 768 && innerWidth < 1024
                  ? `dropdownMediumEN`
                  : i18n.language === `en` && innerWidth >= 1024
                  ? `dropdownEN`
                  : i18n.language === `ar` &&
                    innerWidth >= 768 &&
                    innerWidth < 1024
                  ? `dropdownMediumAR`
                  : i18n.language === `ar` && innerWidth >= 1024
                  ? `dropdownAR`
                  : ``
              }
            >
              <div className="py-3 px-4">
                <span className="block text-sm text-gray-900 dark:text-white">
                  {userName}
                </span>
                <span className="block text-sm font-medium text-gray-500 truncate dark:text-gray-400">
                  {userEmail}
                </span>
              </div>
              {userEmail ? (
                <ul className="py-1" aria-labelledby="dropdown">
                  <li>
                    <Link to={`/${i18n.language}/user/userProfile`}>
                      <span className="block py-2 px-4 text-sm text-gray-700 hover:bg-gray-100">
                        {t('dropdown.your_profile')}{' '}
                      </span>
                    </Link>
                  </li>
                  <li
                    onClick={handleUserNavigation}
                    onKeyDown={handleUserNavigation}
                    aria-hidden="true"
                  >
                    <span className="block py-2 px-4 text-sm text-gray-700 hover:bg-gray-100 cursor-pointer">
                      {t('dropdown.sign_out')}{' '}
                    </span>
                  </li>
                </ul>
              ) : (
                ``
              )}
            </div>
          </div>

          <div className="flex md:order-2 items-center justify-center mx-auto">
            <div
              className="hidden relative mr-3 md:mr-0 lg:block"
              style={{
                width:
                  innerWidth > 768 && innerWidth <= 1116
                    ? `400px`
                    : innerWidth > 1116 && innerWidth <= 1279
                    ? `150px`
                    : innerWidth > 1292 && innerWidth <= 1350
                    ? `250px`
                    : innerWidth > 1350 && innerWidth <= 1536
                    ? `300px`
                    : innerWidth > 1536
                    ? `400px`
                    : `200px`
              }}
            >
              {i18n.language === `en` ? (
                <div className="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
                  <svg
                    className="w-5 h-5 text-gray-500"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                      clipRule="evenodd"
                    ></path>
                  </svg>
                </div>
              ) : (
                ``
              )}
              <input
                onChange={handleSubmitSearchBar}
                onBlur={() =>
                  setTimeout(() => {
                    sitHideSearchDropdown(true);
                  }, 100)
                }
                type="text"
                autoComplete="off"
                id="email-adress-icon"
                className="block p-2 pl-10 w-10/12 text-gray-900 bg-gray-50 rounded-lg border border-gray-300 sm:text-sm step10"
                placeholder={`${t('actions.search')}`}
              />
              {appeardropDownSearchData && (
                <div
                  className={`${
                    hideSearchDropdown
                      ? 'hidden'
                      : 'text-left bg-white h-40 mt-1 p-2 overflow-y-scroll max-h-60 w-72 overflow-y-auto absolute'
                  }`}
                  style={{
                    zIndex: `100`
                  }}
                >
                  {categsData}
                  {serviceResult}
                  {serviceByCateg}
                </div>
              )}
            </div>

            <button
              type="button"
              className="inline-flex items-center p-2 text-sm text-gray-500 rounded-lg md:hidden"
              aria-controls="mobile-menu-3"
              aria-expanded="false"
              onClick={toggleUserMenu}
            >
              <span className="sr-only">Open main menu</span>
              {userMenu ? (
                <XIcon
                  className={
                    i18n.language === `en` ? 'w-6 h-6 ml-10' : 'w-6 h-6 mr-10'
                  }
                  aria-hidden="true"
                />
              ) : (
                <MenuIcon
                  className={
                    i18n.language === `en` ? 'w-6 h-6 ml-10' : 'w-6 h-6 mr-10'
                  }
                  aria-hidden="true"
                />
              )}
            </button>
          </div>
          <div
            className={
              userMenu
                ? 'justify-between items-center w-full md:flex md:w-auto md:order-1 block'
                : 'justify-between items-center w-full md:flex md:w-auto md:order-1 hidden'
            }
            style={{
              marginLeft: innerWidth >= 1116 ? `auto` : ``,
              marginRight: innerWidth >= 1116 ? `auto` : ``,
              backgroundColor: innerWidth < 765 ? `#EBEBEB` : `white`
            }}
            id="mobile-menu-3"
          >
            {userEmail ? (
              <div className="w-full block sm:hidden justify-center mt-5">
                <div
                  className="flex flex-row w-full items-center"
                  style={{ margin: `auto` }}
                >
                  <div className="w-1/4">
                    <img
                      src={avatar}
                      alt={userName}
                      style={{
                        border: `4px solid #FFF`,
                        borderRadius: `50%`
                      }}
                    />
                  </div>
                  <div
                    className="w-2/3"
                    style={{
                      marginRight: i18n.language === 'ar' ? `10px` : ``,
                      marginLeft: i18n.language === 'en' ? `10px` : ``
                    }}
                  >
                    <h1
                      style={{
                        color: `#000`,
                        fontSize: `26px`,
                        lineHeight: `32px`,
                        fontWeight: `700`
                      }}
                    >
                      {userName}
                    </h1>
                    <h2
                      style={{
                        color: `#1A1A1A`,
                        fontSize: `12px`,
                        lineHeight: `23px`,
                        fontWeight: `500`
                      }}
                    >
                      {userEmail}
                    </h2>
                    <h2
                      style={{
                        color: `#1A1A1A`,
                        fontSize: `12px`,
                        lineHeight: `23px`,
                        fontWeight: `500`
                      }}
                    >
                      Total Points: {userPoints}
                    </h2>
                    {userEmail ? (
                      <Link
                        to={`/${i18n.language}/user/userProfile`}
                        onClick={toggleUserMenu}
                      >
                        <span
                          className="block mb-5 py-2 px-4 text-xs font-bold rounded-xl w-1/2 text-center text-white"
                          style={{ backgroundColor: `rgb(223, 138, 64)` }}
                        >
                          {t('dropdown.your_profile')}{' '}
                        </span>
                      </Link>
                    ) : (
                      ``
                    )}
                  </div>
                </div>
              </div>
            ) : (
              ``
            )}

            {navigation.map((item) => (
              <Link
                className={i18n.language}
                to={
                  `#`
                  // item.name === t('common.the_wedding')
                  //   ? `/${i18n.language}/moreWeddings`
                  //   : item.name === t('common.her')
                  //   ? `/${i18n.language}/moreHer`
                  //   : item.name === t('common.him')
                  //   ? `/${i18n.language}/moreHim`
                  //   : `#`
                }
              >
                {innerWidth < 768 ? (
                  <Accordion
                    allowZeroExpanded={true}
                    allowMultipleExpanded={false}
                    style={{ backgroundColor: `#EBEBEB` }}
                  >
                    <AccordionItem style={{ backgroundColor: `#EBEBEB` }}>
                      <AccordionItemHeading
                        className={
                          item.name === 'Take a Tour' ||
                          item.name === 'الموقع في 10 ثواني'
                            ? 'hidden'
                            : 'block'
                        }
                        style={{ backgroundColor: `#EBEBEB` }}
                      >
                        <AccordionItemButton
                          className={
                            i18n.language === 'en' ? 'buttonEn' : 'buttonAr'
                          }
                          style={{ backgroundColor: `#EBEBEB` }}
                        >
                          {item.name}
                        </AccordionItemButton>
                      </AccordionItemHeading>
                      {item.list.map((i) => (
                        <AccordionItemPanel
                          onClick={toggleUserMenu}
                          style={{ backgroundColor: `#EBEBEB` }}
                        >
                          <Link to={i.href}>
                            <p className="cursor-pointer hover:border-t hover:border-b">
                              {i.name}
                            </p>
                          </Link>
                        </AccordionItemPanel>
                      ))}
                    </AccordionItem>
                  </Accordion>
                ) : (
                  <DropDown className="flex flex-col mt-4 md:flex-row md:space-x-2 xl:space-x-2 md:mt-2 md:text-sm md:font-medium mx-3 mb-2 ">
                    {item.name === `Take a Tour` ||
                    item.name === 'الموقع في ١٠ ثواني' ? (
                      <button
                        type="button"
                        className={
                          role
                            ? 'hidden'
                            : `flex mr-3 text-sm rounded-full md:mr-0 ${item.step}`
                        }
                        onClick={() => {
                          localStorage.removeItem('firstVisit');
                          window.location.reload();
                        }}
                        id="user-menu-button"
                      >
                        {item.name}
                      </button>
                    ) : (
                      <button
                        type="button"
                        className={`flex mr-3 text-sm rounded-full md:mr-0 ${item.step}`}
                        id="user-menu-button"
                      >
                        {item.name}
                      </button>
                    )}

                    <div className=" z-50 my-0 md:my-4 text-base list-none bg-white justify-center items-center ml-6">
                      <DropDownMenu
                        className="z-10 shadow dark-mode:bg-gray-800 absolute hidden text-gray-700"
                        id={
                          innerWidth < 768 && i18n.language === `en`
                            ? `dropdownSmallEn`
                            : innerWidth < 768 && i18n.language === `ar`
                            ? `dropdownSmallAr`
                            : innerWidth >= 768 && i18n.language === `en`
                            ? `dropdownLargeEn`
                            : innerWidth >= 768 && i18n.language === `ar`
                            ? `dropdownLargeAr`
                            : ` `
                        }
                      >
                        {item.list.map((i) => (
                          <li>
                            <Link to={i.href} onClick={toggleUserMenu}>
                              <span
                                className="block py-2 px-4 font-normal text-sm cursor-pointer hover:border-t hover:border-b"
                                id="dropdownItem"
                              >
                                {i.name}
                              </span>
                            </Link>
                          </li>
                        ))}
                      </DropDownMenu>
                    </div>
                  </DropDown>
                )}
              </Link>
            ))}
            {innerWidth < 768 ? (
              <div
                className="z-50 my-4 text-base list-none bg-white rounded divide-y divide-gray-100 lg:absolute"
                style={{ backgroundColor: `#EBEBEB` }}
              >
                {userEmail ? (
                  <ul className="py-1" aria-labelledby="dropdown">
                    <li
                      onClick={handleUserNavigation}
                      onKeyDown={handleUserNavigation}
                      aria-hidden="true"
                    >
                      <span className="block py-2 px-4 text-sm font-bold bg-black rounded-xl w-1/3 text-center mx-auto text-white">
                        {t('dropdown.sign_out')}{' '}
                      </span>
                    </li>
                  </ul>
                ) : (
                  <div
                    className="z-50 my-4 text-base list-none bg-white rounded divide-y divide-gray-100 lg:absolute"
                    style={{ backgroundColor: `#EBEBEB` }}
                  >
                    <ul className="py-1" aria-labelledby="dropdown">
                      <li onClick={toggleUserMenu}>
                        <Link to={`/${i18n.language}/Login`}>
                          <span
                            className="block mb-5 py-2 px-4 text-sm font-bold rounded-xl w-1/3 text-center mx-auto text-white"
                            style={{ backgroundColor: `#076A6D` }}
                          >
                            {t('buttons.sign_in')}{' '}
                          </span>
                        </Link>
                      </li>
                      <li onClick={toggleUserMenu}>
                        <Link to={`/${i18n.language}/SignUp`}>
                          <span className="block py-2 px-4 text-sm font-bold over:bg-gray-100 bg-black rounded-xl w-1/3 text-center mx-auto text-white">
                            {t('buttons.sign_up')}{' '}
                          </span>
                        </Link>
                      </li>
                    </ul>
                  </div>
                )}
              </div>
            ) : (
              ``
            )}
          </div>
        </div>
      </nav>
    </div>
  );
};
