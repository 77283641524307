/* eslint-disable @typescript-eslint/no-explicit-any */

import React, { useState, useCallback, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { BASE_URL, CLIENT_REF } from 'src/config/app.config';
import axios from 'axios';

import Lobby from './Lobby';
import Room from './Room';

const VideoChat = (): JSX.Element => {
  const [width, setWidth] = useState<number>(window.innerWidth);
  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, []);
  const isMobile: boolean = width <= 640;
  setTimeout(() => {
    const videos = document.querySelectorAll('video');
    videos.forEach((video: any) => {
      video.width = isMobile
        ? window.screen.availWidth * 0.8
        : window.screen.availWidth * 0.4;
    });
  }, 10000);
  const [username, setUsername] = useState<string>('');
  const { roomName }: { roomName: string } = useParams();

  const [token, setToken] = useState<string | null>();

  const handleUsernameChange = useCallback(
    (event) => {
      setUsername(event.target.value);
    },
    [setUsername]
  );

  const handleSubmit = useCallback(
    async (event) => {
      event.preventDefault();
      const data = await axios({
        method: `get`,
        withCredentials: false,
        url: `${BASE_URL}message/get-at-for-video-chat/${CLIENT_REF}/${username}/${roomName}`,
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*'
        }
      });
      setToken(data.data.data.data);
      localStorage.setItem('roomName', roomName);
    },

    [roomName, username]
  );

  const handleLogout = useCallback(() => {
    setToken(null);
  }, []);

  let render;
  if (token) {
    render = (
      <Room roomName={roomName} token={token} handleLogout={handleLogout} />
    );
  } else {
    render = (
      <Lobby
        username={username}
        handleUsernameChange={handleUsernameChange}
        handleSubmit={handleSubmit}
      />
    );
  }
  return render;
};

export default VideoChat;
