import i18next, { InitOptions } from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

import translationsEN from './locales/en.json';
import translationsAR from './locales/ar.json';

const options: InitOptions = {
  fallbackLng: 'en',
  supportedLngs: ['en', 'ar'],
  ns: ['translations'],
  defaultNS: 'translations',
  detection: {
    order: ['path', 'localStorage'],
    lookupFromPathIndex: 0
  },
  resources: {
    en: { translations: translationsEN },
    ar: { translations: translationsAR }
  },

  debug: true,

  interpolation: {
    escapeValue: false, // not needed for react!!
    formatSeparator: ',',
    format: (value: string, format?: string) => {
      if (format === 'uppercase') return value.toUpperCase();
      return value;
    }
  },
  react: {
    wait: process && !process.release,
    useSuspense: false
  }
};

i18next.use(LanguageDetector).use(initReactI18next).init(options);

export const i18n = i18next;
