/* eslint-disable @typescript-eslint/no-explicit-any */
import axios from 'axios';
import { ACCESS_TOKEN, BASE_URL, CLIENT_REF } from 'src/config/app.config';

const API_URL = `${BASE_URL}admin`;

interface Category {
  _id: string;
  photo: string;
  icon: string;
  nameEn: string;
  nameAr: string;
  descriptionEn: string;
  descriptionAr: string;
}

export class CategoryService {
  static async getServiceByCategory(id: string): Promise<any> {
    try {
      const response = await axios({
        method: `GET`,
        url: `${API_URL}/get-service-by-category/${CLIENT_REF}/${id}`,
        headers: {
          'x-access-token': ACCESS_TOKEN
        }
      });

      return response.data.data;
    } catch (err: any) {
      throw new Error(
        `${err.response.statusText} - ${err.response.data.message}`
      );
    }
  }

  static async getCategoryForHim(): Promise<Category[]> {
    try {
      const response = await axios({
        method: `GET`,
        url: `${API_URL}/get-categories-by-segment/${CLIENT_REF}/HIM`,
        headers: {
          'x-access-token': ACCESS_TOKEN
        }
      });

      return response.data.data;
    } catch (err: any) {
      throw new Error(
        `${err.response.statusText} - ${err.response.data.message}`
      );
    }
  }

  static async getCategoryForHer(): Promise<Category[]> {
    try {
      const response = await axios({
        method: `GET`,
        url: `${API_URL}/get-categories-by-segment/${CLIENT_REF}/HER`,
        headers: {
          'x-access-token': ACCESS_TOKEN
        }
      });

      return response.data.data;
    } catch (err: any) {
      throw new Error(
        `${err.response.statusText} - ${err.response.data.message}`
      );
    }
  }

  static async getCategoryForWedding(): Promise<Category[]> {
    try {
      const response = await axios({
        method: `GET`,
        url: `${API_URL}/get-categories-by-segment/${CLIENT_REF}/WEDDING`,
        headers: {
          'x-access-token': ACCESS_TOKEN
        }
      });

      return response.data.data;
    } catch (err: any) {
      throw new Error(
        `${err.response.statusText} - ${err.response.data.message}`
      );
    }
  }
}
