/* eslint-disable @typescript-eslint/no-explicit-any */
import { useTranslation } from 'react-i18next';

interface Properties {
  text: any;
  content?: any;
  css?: string;
  typeofButton?: JSX.IntrinsicElements['button']['type'];
  onClickFunction?: () => any;
  key?: string;
  disabled?: boolean;
}

const Button = (prop: Properties): JSX.Element => {
  const { t } = useTranslation();
  const { text, content, typeofButton, css, onClickFunction, key, disabled } =
    prop;

  return (
    <button
      className={`px-8 py-2 font-medium transform transition hover:scale-105 ${css}`}
      type={typeofButton}
      onClick={onClickFunction}
      id={key}
      disabled={disabled}
    >
      {content}
      {t(text)}
    </button>
  );
};

export default Button;
