import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import Form from '../LoginAccount/SignInForm';
import VideoChat from './VideoChat';

const TwilioVideo = (): JSX.Element => {
  const { roomName }: { roomName: string } = useParams();

  const logged = localStorage.getItem('loggedIn');
  return (
    <>
      {}
      <Helmet>
        <title>Video Consultation</title>
        <meta
          name="description"
          content="Everything you need to plan your wedding,literally! Wedding dresses, planning tools, wedding ideas, inspiration,photos, plus the best wedding vendors. Weds360 wedding checklist keeps youorganized."
        />
        <meta
          name="keywords"
          content="weds360, wedding, pre-wedding , wedding day, wedding ideas , wedding dresses, wedding dresses 2020, wedding beach dress, sleeved wedding dress, wedding dresses for hijab, off shoulder wedding dress, wedding veil, wedding veils style, wedding tailor"
        />
      </Helmet>
      <div className="pt-28">
        {logged ? (
          <VideoChat />
        ) : (
          <div>
            <Form isTwilioVideo roomName={roomName} />{' '}
          </div>
        )}
      </div>
    </>
  );
};

export default TwilioVideo;
