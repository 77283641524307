/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-explicit-any */
import axios from 'axios';
import { ACCESS_TOKEN, BASE_URL, CLIENT_REF } from 'src/config/app.config';

const API_URL = `${BASE_URL}vendors`;

interface FAQ {
  questionEn: string;
  questionAr: string;
  answerEn: string;
  answerAr: string;
}

interface ServiceInterface {
  vendor: string;
  _id: string;
  type: string;
  FAQs: FAQ[];
  vendorNameEn: string;
  vendorNameAr: string;
  images: string[];
  averageRate: number;
  reviewsCount: number;
  vendorPhone: number;
  websiteURL: string;
  relatedServicesData: {
    relatedServiceId: string;
    relatedServicePhoto: string;
    relatedServiceNameEn: string;
    relatedServiceNameAr: string;
  }[];

  nameEn: string;
  descriptionEn: string;
  tagsEnName: string[];
  nameAr: string;
  descriptionAr: string;
  category: string;
  userInLoves: string;
  numberOfLoves: number;
}

// interface CategoryServices {
//   _id: string;

//   featuredImage: string;
//   nameEn: string;
//   descriptionEn: string;
//   nameAr: string;
// }

interface TierA {
  _id: string;

  featuredImage: string;
  nameEn: string;
  descriptionEn: string;
  nameAr: string;
  vendorNameEn?: string;
}

interface TierB {
  _id: string;

  featuredImage: string;
  nameEn: string;
  descriptionEn: string;
  descriptionAr: string;
  nameAr: string;

  vendorNameEn?: string;
}

interface CommentInterface {
  userName: string;
  body: string;
  userAvatar: string;
  value: number;
}

interface Review {
  body: string;
  value: number;
}

/* interface HomeServiceData {
  serviceNameEn: string;
  serviceNameAr: string;
  serviceFeaturedImage: string;
} */

/* interface HomeService {
  Venues: HomeServiceData[];
  Designers: HomeServiceData[];
  BridalBoutique: HomeServiceData[];
  WeddingDresses: HomeServiceData[];
  Rings: HomeServiceData[];
  Videographers: HomeServiceData[];
  Photographers: HomeServiceData[];
  DJs: HomeServiceData[];
  WeddingPlanners: HomeServiceData[];
  WeddingCakes: HomeServiceData[];
  MakeupArtists: HomeServiceData[];
  CandyStations: HomeServiceData[];
  Entertainment: HomeServiceData[];
  Caterers: HomeServiceData[];
  Beauty: HomeServiceData[];
  Spas: HomeServiceData[];
  Hairdressers: HomeServiceData[];
  InvitationsAndPaperGoods: HomeServiceData[];
  InvitationsAndPaperGoods: HomeServiceData[];
  InvitationsAndPaperGoods: HomeServiceData[];
} */

const userToken = localStorage.getItem('authToken');
const userEmail = localStorage.getItem('email');
const userId = localStorage.getItem('userID');

export class VendorService {
  static async getServiceByCategory(
    id: string,
    limit: number,
    skip: number
  ): Promise<any> {
    try {
      const response = await axios({
        method: `GET`,
        url: `${API_URL}/get-service-by-category/${CLIENT_REF}/${id}?skip=${limit}&limit=${skip}`,
        headers: {
          'x-access-token': ACCESS_TOKEN
        }
      });

      return response.data.data;
    } catch (err: any) {
      throw new Error(
        `${err.response.statusText} - ${err.response.data.message}`
      );
    }
  }

  static async getAllVendorServices(): Promise<any> {
    try {
      const response = await axios({
        method: `GET`,
        url: `${API_URL}/get-all-vendor-services/${CLIENT_REF}/hossammamdouh8010@gmail.com?list=all`,
        headers: {
          'x-access-token': ACCESS_TOKEN
        }
      });

      return response.data.data;
    } catch (err: any) {
      throw new Error(
        `${err.response.statusText} - ${err.response.data.message}`
      );
    }
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  static async getVendorById(vendorId: any): Promise<any> {
    try {
      const response = await axios({
        method: `POST`,
        url: `${API_URL}/get-vendor-by-id/${CLIENT_REF}/${userEmail}?vendorId=${vendorId}`,
        headers: {
          'x-access-token': ACCESS_TOKEN
        }
      });

      return response.data.data;
    } catch (err: any) {
      throw new Error(
        `${err.response.statusText} - ${err.response.data.message}`
      );
    }
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  static async updateVendor(vendor: any): Promise<any> {
    try {
      const response = await axios({
        method: `POST`,
        url: `${API_URL}/update-vendor/${CLIENT_REF}`,
        headers: {
          'x-access-token': ACCESS_TOKEN
        },
        data: { vendor }
      });

      return response.data.data;
    } catch (err: any) {
      throw new Error(
        `${err.response.statusText} - ${err.response.data.message}`
      );
    }
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  static async getVendorSlots(serviceId: string): Promise<any> {
    try {
      const response = await axios({
        method: `GET`,
        url: `${API_URL}/get-vendor-slots/${CLIENT_REF}/${serviceId}`,
        headers: {
          'x-access-token': ACCESS_TOKEN
        }
      });

      return response.data.data;
    } catch (err: any) {
      throw new Error(
        `${err.response.statusText} - ${err.response.data.message}`
      );
    }
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  static async getVendorServiceSchedule(serviceId: string): Promise<any> {
    try {
      const response = await axios({
        method: `GET`,
        url: `${API_URL}/get-vendor-service-schedule/${CLIENT_REF}/${serviceId}`,
        headers: {
          'x-access-token': ACCESS_TOKEN
        }
      });

      return response.data.data;
    } catch (err: any) {
      throw new Error(
        `${err.response.statusText} - ${err.response.data.message}`
      );
    }
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  static async upsertSchedule(schedule: any, serviceId: string): Promise<any> {
    try {
      const response = await axios({
        method: `POST`,
        url: `${API_URL}/upsert-schedule/${CLIENT_REF}`,
        headers: {
          'x-access-token': ACCESS_TOKEN
        },
        data: { schedule, serviceId }
      });

      return response.data.data;
    } catch (err: any) {
      throw new Error(
        `${err.response.statusText} - ${err.response.data.message}`
      );
    }
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  static async createService(service: any, email: any): Promise<any> {
    try {
      const response = await axios({
        method: `POST`,
        url: `${API_URL}/create-new-service/${CLIENT_REF}/${email}`,
        headers: {
          'x-access-token': ACCESS_TOKEN
        },
        data: { service }
      });

      return response.data;
    } catch (err: any) {
      throw new Error(
        `${err.response.statusText} - ${err.response.data.message}`
      );
    }
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  static async getVendorServices(vendorId: any): Promise<any> {
    try {
      const response = await axios({
        method: `GET`,
        url: `${API_URL}/get-all-vendor-services/${CLIENT_REF}/khaled.hady@weds360-vendor.com?vendorId=${vendorId}&vendorRequest=true`,
        headers: {
          'x-access-token': ACCESS_TOKEN
        }
      });

      return response.data.data;
    } catch (err: any) {
      throw new Error(
        `${err.response.statusText} - ${err.response.data.message}`
      );
    }
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  static async bookVendorServiceSlot(body: any): Promise<any> {
    try {
      const response = await axios({
        method: `POST`,
        url: `${API_URL}/book-vendor-service-slot/${CLIENT_REF}`,
        headers: {
          'x-access-token': ACCESS_TOKEN
        },
        data: { ...body }
      });

      return response.data.data;
    } catch (err: any) {
      throw new Error(
        `${err.response.statusText} - ${err.response.data.message}`
      );
    }
  }

  static async getServiceById(id: string): Promise<ServiceInterface> {
    try {
      const response = await axios({
        method: `GET`,
        url: `${API_URL}/get-service-by-id/${CLIENT_REF}/${userEmail}?serviceId=${id}`,
        headers: {
          'x-access-token': ACCESS_TOKEN
        }
      });
      return response.data.data;
    } catch (err: any) {
      throw new Error(
        `${err.response.statusText} - ${err.response.data.message}`
      );
    }
  }

  static async LikesData(): Promise<any> {
    try {
      const response = await axios({
        method: `GET`,
        url: `${BASE_URL}/users/get-user-likes/${CLIENT_REF}/${userEmail}`,
        headers: {
          'x-access-token': ACCESS_TOKEN
        }
      });
      return response.data.data;
    } catch (err: any) {
      throw new Error(
        `${err.response.statusText} - ${err.response.data.message}`
      );
    }
  }

  static async getServiceByRailsId(id: string): Promise<ServiceInterface> {
    try {
      const response = await axios({
        method: `GET`,
        url: `${API_URL}/get-service-by-rails-id/${CLIENT_REF}/${id}`,
        headers: {
          'x-access-token': ACCESS_TOKEN
        }
      });
      return response.data.data;
    } catch (err: any) {
      throw new Error(
        `${err.response.statusText} - ${err.response.data.message}`
      );
    }
  }

  static async getTierAFeaturedServices(tier: string): Promise<TierA[]> {
    try {
      const response = await axios({
        method: `GET`,
        url: `${API_URL}/get-featured-services/${CLIENT_REF}?tier=${tier}&limit=0&skip=0`,
        headers: {
          'x-access-token': ACCESS_TOKEN
        }
      });
      return response.data.data;
    } catch (err: any) {
      throw new Error(
        `${err.response.statusText} - ${err.response.data.message}`
      );
    }
  }

  static async getTierBFeaturedServices(tier: string): Promise<Array<TierB>> {
    try {
      const response = await axios({
        method: `GET`,
        url: `${API_URL}/get-featured-services/${CLIENT_REF}?tier=${tier}&limit=0&skip=0`,
        headers: {
          'x-access-token': ACCESS_TOKEN
        }
      });

      return response.data.data;
    } catch (err: any) {
      throw new Error(
        `${err.response.statusText} - ${err.response.data.message}`
      );
    }
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  static async updateService(service: any, images: string[]): Promise<any> {
    try {
      const response = await axios({
        method: `POST`,
        url: `${API_URL}/update-service/${CLIENT_REF}/${userEmail}`,
        headers: {
          'x-access-token': ACCESS_TOKEN
        },
        data: { service, images }
      });

      return response.data.data;
    } catch (err: any) {
      throw new Error(
        `${err.response.statusText} - ${err.response.data.message}`
      );
    }
  }

  static async getServiceComments(id: string): Promise<CommentInterface[]> {
    try {
      const response = await axios({
        method: `GET`,
        url: `${API_URL}/get-all-comments/${CLIENT_REF}/${id}?limit=5&skip=0`,
        headers: {
          'x-access-token': ACCESS_TOKEN
        }
      });

      return response.data.data;
    } catch (err: any) {
      throw new Error(
        `${err.response.statusText} - ${err.response.data.message}`
      );
    }
  }

  static async addComment(
    serviceId: string,
    usermail: string,
    comment: Review
  ): Promise<unknown> {
    try {
      await axios({
        method: `POST`,
        url: `${API_URL}/create-new-comment/${CLIENT_REF}/${serviceId}/${usermail}`,
        data: { comment },
        headers: {
          'x-access-token': ACCESS_TOKEN
        }
      });

      return;
    } catch (err: any) {
      throw new Error(
        `${err.response.statusText} - ${err.response.data.message}`
      );
    }
  }

  static async SetPhoneNumberViewCount(serviceId: string): Promise<unknown> {
    try {
      await axios({
        method: `GET`,
        url: `${API_URL}/set-phone-view-count/${CLIENT_REF}/${serviceId}`,
        headers: {
          'x-access-token': ACCESS_TOKEN
        }
      });

      return;
    } catch (err: any) {
      throw new Error(
        `${err.response.statusText} - ${err.response.data.message}`
      );
    }
  }

  static async SetPageViewCount(serviceId: string): Promise<unknown> {
    try {
      await axios({
        method: `GET`,
        url: `${API_URL}/set-service-view-count/${CLIENT_REF}?serviceId=${serviceId}`,
        headers: {
          'x-access-token': ACCESS_TOKEN
        }
      });

      return;
    } catch (err: any) {
      throw new Error(
        `${err.response.statusText} - ${err.response.data.message}`
      );
    }
  }

  static async getAllServices(): Promise<any> {
    try {
      const response = await axios({
        method: `GET`,
        url: `${API_URL}/get-all-services/${CLIENT_REF}/${userEmail}`,
        headers: {
          'x-access-token': ACCESS_TOKEN
        }
      });

      return response.data.data;
    } catch (err: any) {
      throw new Error(
        `${err.response.statusText} - ${err.response.data.message}`
      );
    }
  }

  static async getHomeServices(): Promise<any> {
    try {
      const response = await axios({
        method: `GET`,
        url: `${BASE_URL}vendors/get-home-services/${CLIENT_REF}/${userEmail}`,
        headers: {
          'x-access-token': `${userToken}`
        }
      });
      return response.data.data;
    } catch (err: any) {
      throw new Error(
        `${err.response.statusText} - ${err.response.data.message}`
      );
    }
  }

  static async getPoints(): Promise<any> {
    try {
      const response = await axios({
        method: `GET`,
        url: `${BASE_URL}/payment/get-points/${CLIENT_REF}?userId=${userId}`,
        headers: {
          'x-access-token': `${userToken}`
        }
      });
      return response;
    } catch (err: any) {
      throw new Error(
        `${err.response.statusText} - ${err.response.data.message}`
      );
    }
  }

  static async getAllHomeDressess(): Promise<any> {
    try {
      const response = await axios({
        method: `GET`,
        url: `${API_URL}/get-home-dresses/${CLIENT_REF}`,
        headers: {
          'x-access-token': ACCESS_TOKEN
        }
      });

      return response.data.data;
    } catch (err: any) {
      throw new Error(
        `${err.response.statusText} - ${err.response.data.message}`
      );
    }
  }

  static async getAllHomeRings(): Promise<any> {
    try {
      const response = await axios({
        method: `GET`,
        url: `${API_URL}/get-home-rings/${CLIENT_REF}`,
        headers: {
          'x-access-token': ACCESS_TOKEN
        }
      });

      return response.data.data;
    } catch (err: any) {
      throw new Error(
        `${err.response.statusText} - ${err.response.data.message}`
      );
    }
  }

  static async getAllVendors(): Promise<any> {
    try {
      const response = await axios({
        method: `GET`,
        url: `${API_URL}/get-all-vendors/${CLIENT_REF}`,
        headers: {
          'x-access-token': ACCESS_TOKEN
        }
      });

      return response.data.data;
    } catch (err: any) {
      throw new Error(
        `${err.response.statusText} - ${err.response.data.message}`
      );
    }
  }

  static async getMeetings(): Promise<any> {
    try {
      const response = await axios({
        method: `GET`,
        url: `${API_URL}/get-twilio-room-data/${CLIENT_REF}/${userId}`,
        headers: {
          'x-access-token': ACCESS_TOKEN
        }
      });

      return response.data.data;
    } catch (err: any) {
      throw new Error(
        `${err.response.statusText} - ${err.response.data.message}`
      );
    }
  }

  static async getTwilioRoomDataForVendor(vendorId: string): Promise<any> {
    try {
      const response = await axios({
        method: `GET`,
        url: `${API_URL}/get-twilio-room-data-for-vendor/${CLIENT_REF}/${vendorId}`,
        headers: {
          'x-access-token': ACCESS_TOKEN
        }
      });

      return response.data.data;
    } catch (err: any) {
      throw new Error(
        `${err.response.statusText} - ${err.response.data.message}`
      );
    }
  }
}
