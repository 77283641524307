/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import axios from 'axios';
import { ACCESS_TOKEN, BASE_URL, CLIENT_REF } from 'src/config/app.config';

const API_URL = `${BASE_URL}users`;
const userEmail = localStorage.getItem('email') as string;

export class UsersService {
  static async messageVendor(
    vendorId: string,
    message: unknown
  ): Promise<boolean> {
    try {
      const response = await axios({
        method: `POST`,
        url: `${API_URL}/contact-vendor/${CLIENT_REF}/${vendorId}`,
        data: { message },
        headers: {
          'x-access-token': ACCESS_TOKEN
        }
      });
      console.log(response.data.error, 'response');
      return response.data.error;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (err: any) {
      throw new Error(
        `${err.response.statusText} - ${err.response.data.message}`
      );
    }
  }

  static async userSignUp(user: any): Promise<any> {
    try {
      const response = await axios({
        method: `POST`,
        url: `${API_URL}/sign-up/${CLIENT_REF}`,
        data: { user },
        headers: {
          'x-access-token': ACCESS_TOKEN
        }
      });

      return response.data;

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (err: any) {
      throw new Error(
        `${err.response.statusText} - ${err.response.data.message}`
      );
    }
  }

  static async userSignIn(userCredentials: any): Promise<any> {
    try {
      const response = await axios({
        method: `POST`,
        url: `${API_URL}/sign-in/${CLIENT_REF}`,
        data: { userCredentials },
        headers: {
          'x-access-token': ACCESS_TOKEN
        }
      });

      return response.data;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (err: any) {
      throw new Error(
        `${err.response.statusText} - ${err.response.data.message}`
      );
    }
  }

  static async requestPasswordReset(
    email: string,
    token: string
  ): Promise<unknown> {
    try {
      const response = await axios({
        method: `POST`,
        url: `${API_URL}/request-password-reset/${CLIENT_REF}/${email}`,
        headers: {
          'x-access-token': `${token}`
        }
      });

      return response.data.data;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (err: any) {
      throw new Error(
        `${err.response.statusText} - ${err.response.data.message}`
      );
    }
  }

  static async userSignInFaceBook(user: any): Promise<unknown> {
    try {
      const response = await axios({
        method: `POST`,
        url: `${API_URL}/social-logging/${CLIENT_REF}`,
        data: { user }
      });

      return response.data;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (err: any) {
      throw new Error(
        `${err.response.statusText} - ${err.response.data.message}`
      );
    }
  }

  static async getUserByEmail(user: any): Promise<unknown> {
    try {
      console.log('function user', user);
      const response = await axios({
        method: `GET`,
        url: `${API_URL}/get-user-by-email/${CLIENT_REF}?email=${user}`
      });

      return response.data;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (err: any) {
      throw new Error(
        `${err.response.statusText} - ${err.response.data.message}`
      );
    }
  }

  static async confirmEmail(): Promise<unknown> {
    try {
      await axios({
        method: `POST`,
        url: `${API_URL}/confirm-email/${CLIENT_REF}/${userEmail}`,
        headers: {
          'x-access-token': ACCESS_TOKEN
        }
      });

      return;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (err: any) {
      throw new Error(
        `${err.response.statusText} - ${err.response.data.message}`
      );
    }
  }

  static async getUserById(id: any): Promise<any> {
    try {
      const response = await axios({
        method: `GET`,
        url: `${API_URL}/get-user-by-id/${CLIENT_REF}/alameerelnagar@yahoo.com?id=${id}`,
        headers: {
          'x-access-token': ACCESS_TOKEN
        }
      });

      return response.data.data;
    } catch (err: any) {
      throw new Error(
        `${err.response.statusText} - ${err.response.data.message}`
      );
    }
  }

  static async upadteUserInfo(user: any, userId: any): Promise<unknown> {
    try {
      await axios({
        method: `POST`,
        url: `${API_URL}/update-user-personal-info/${CLIENT_REF}?userId=${userId}`,
        headers: {
          'x-access-token': ACCESS_TOKEN
        },
        data: { user }
      });

      return;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (err: any) {
      throw new Error(
        `${err.response.statusText} - ${err.response.data.message}`
      );
    }
  }

  static async upadteUserInfoForEmailReset(
    user: any,
    userId: any,
    emailToken: string,
    errorToken: string
  ): Promise<unknown> {
    try {
      const response = await axios({
        method: `POST`,
        url: `${API_URL}/update-user-personal-info/${CLIENT_REF}?userId=${userId}`,
        headers: {
          'x-access-token': `${errorToken}`
        },
        data: { user }
      });

      return response.data;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (err: any) {
      throw new Error(
        `${err.response.statusText} - ${err.response.data.message}`
      );
    }
  }

  static async resetPassword(resetData: any, emailToken: any): Promise<any> {
    try {
      const response = await axios({
        method: `POST`,
        url: `${API_URL}/reset-password/${CLIENT_REF}?mode=email`,
        headers: {
          'x-access-token': `${emailToken}`
        },
        data: { resetData }
      });

      return response.data;
    } catch (err: any) {
      throw new Error(
        `${err.response.statusText} - ${err.response.data.message}`
      );
    }
  }

  static async resetPasswordForUsersWithNoPassword(
    resetData: any
  ): Promise<any> {
    try {
      const response = await axios({
        method: `POST`,
        url: `${API_URL}/reset-password/${CLIENT_REF}?mode=email`,
        headers: {
          'x-access-token': ACCESS_TOKEN
        },
        data: { resetData }
      });

      return response.data;
    } catch (err: any) {
      throw new Error(
        `${err.response.statusText} - ${err.response.data.message}`
      );
    }
  }

  static async sendConfirmationEmail(emailInput: any): Promise<any> {
    try {
      await axios({
        method: `GET`,
        url: `${API_URL}/resend-confirmation-email/${CLIENT_REF}/${emailInput}`,
        headers: {
          'x-access-token': ACCESS_TOKEN
        }
      });

      return;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (err: any) {
      console.log(`error`, err);
      throw new Error(
        `${err.response.statusText} - ${err.response.data.message}`
      );
    }
  }

  static async searchHome(url: any): Promise<unknown> {
    try {
      const response = await axios({
        method: `GET`,
        url: `${url}`,
        headers: {
          'x-access-token': ACCESS_TOKEN
        }
      });

      return response.data.data;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (err: any) {
      throw new Error(
        `${err.response.statusText} - ${err.response.data.message}`
      );
    }
  }

  static async userServiceLove(
    loveValue: string,
    serviceId: string
  ): Promise<any> {
    try {
      const response = await axios({
        method: `POST`,
        url: `${API_URL}/love/${CLIENT_REF}/${loveValue}/${serviceId}/service/${userEmail}`,
        headers: {
          'x-access-token': ACCESS_TOKEN
        }
      });

      return response.data;

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (err: any) {
      throw new Error(
        `${err.response.statusText} - ${err.response.data.message}`
      );
    }
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  static async userClap(id: any): Promise<any> {
    try {
      const response = await axios({
        method: `GET`,
        url: `${API_URL}/clap-for-blog/${CLIENT_REF}/${userEmail}?blogId=${id}`,
        headers: {
          'x-access-token': ACCESS_TOKEN
        }
      });

      return response.data;

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (err: any) {
      throw new Error(
        `${err.response.statusText} - ${err.response.data.message}`
      );
    }
  }

  static async generateReferralCode(userId: string): Promise<any> {
    try {
      const response = await axios({
        method: `GET`,
        url: `${API_URL}/generate-referral-code/${CLIENT_REF}/${userId}`,
        headers: {
          'x-access-token': ACCESS_TOKEN
        }
      });

      return response.data.data;
    } catch (err: any) {
      throw new Error(
        `${err.response.statusText} - ${err.response.data.message}`
      );
    }
  }
}
