/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState, useEffect } from 'react';
import { connect, createLocalTracks } from 'twilio-video';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faMicrophone,
  faMicrophoneSlash,
  faVideo,
  faVideoSlash
} from '@fortawesome/free-solid-svg-icons';
import { i18n } from 'src/i18n';
import Participant from './Participant';

const Room = ({ roomName, token, handleLogout }: any): JSX.Element => {
  const [room, setRoom] = useState<any>(null);
  const [participants, setParticipants] = useState<any>([]);
  const [isAudioTrackOff, setAudioIsTrackOff] = useState<boolean>(false);
  const [isVideoTrackOff, setVideoIsTrackOff] = useState<boolean>(false);
  const [width, setWidth] = useState<number>(window.innerWidth);
  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, []);
  const isMobile: boolean = width <= 640;
  const toggleAudioTrack = () => {
    if (isAudioTrackOff) {
      room?.localParticipant.audioTracks.forEach((track: any) => {
        track.track.enable();
      });
    } else {
      room?.localParticipant.audioTracks.forEach((track: any) => {
        track.track.disable();
      });
    }

    setAudioIsTrackOff(!isAudioTrackOff);
  };

  const toggleVideoTrack = () => {
    if (isVideoTrackOff) {
      room?.localParticipant.videoTracks.forEach((track: any) => {
        track.track.enable();
      });
    } else {
      room?.localParticipant.videoTracks.forEach((track: any) => {
        track.track.disable();
      });
    }

    setVideoIsTrackOff(!isVideoTrackOff);
  };
  useEffect(() => {
    const participantConnected = (participant: any | never) => {
      setParticipants((prevParticipants: any) => [
        ...prevParticipants,
        participant
      ]);
    };

    const participantDisconnected = (participant: any) => {
      setParticipants((prevParticipants: any[]) =>
        prevParticipants.filter((p: any) => p !== participant)
      );
    };

    setTimeout(async () => {
      const tracks = await createLocalTracks({
        audio: true,
        video: {
          facingMode: 'user',
          width: isMobile
            ? window.screen.availWidth * 0.8
            : window.screen.availWidth * 0.4
        }
      });
      connect(`${token}`, {
        name: roomName,
        tracks
      }).then(
        (room) => {
          setRoom(room);
          room.on('participantConnected', participantConnected);
          room.on('participantDisconnected', participantDisconnected);
          room.participants.forEach(participantConnected);
        },
        (error) => {
          /* eslint-disable no-console */
          console.error(`Unable to connect to Room: ${error.message}`);
        }
      );
    }, 1000);

    return () => {
      setRoom(
        (currentRoom: {
          localParticipant: { state: string; tracks: any[] };
          disconnect: () => void;
        }) => {
          if (
            currentRoom &&
            currentRoom.localParticipant.state === 'connected'
          ) {
            currentRoom.localParticipant.tracks.forEach(
              (trackPublication: any) => {
                trackPublication.track.stop();
              }
            );
            currentRoom.disconnect();
            return null;
          }
          return currentRoom;
        }
      );
    };
  }, [roomName, token, isMobile]);

  const remoteParticipants = participants.map((participant: { sid: any }) => (
    <Participant key={participant.sid} participant={participant} />
  ));

  return (
    <div className="relative lg:pt-10">
      <div className="text-center mb-8 block lg:flex container mx-auto">
        {room ? (
          <div className="w-full ">
            <Participant
              key={room.localParticipant.sid}
              participant={room.localParticipant}
            />
            <div className="w-8/12 flex flex-row mx-auto justify-center mt-2">
              <button className="text-green-800" onClick={toggleAudioTrack}>
                {isAudioTrackOff ? (
                  <FontAwesomeIcon
                    className="text-3xl mx-3"
                    icon={faMicrophoneSlash}
                  />
                ) : (
                  <FontAwesomeIcon
                    className="text-3xl mx-3"
                    icon={faMicrophone}
                  />
                )}
              </button>
              <button className="text-green-800" onClick={toggleVideoTrack}>
                {isVideoTrackOff ? (
                  <FontAwesomeIcon
                    className="text-3xl mx-3 "
                    icon={faVideoSlash}
                  />
                ) : (
                  <FontAwesomeIcon className="text-3xl mx-3 " icon={faVideo} />
                )}
              </button>
            </div>
          </div>
        ) : (
          ''
        )}
        <div className="w-full">
          <div className="text-center mb-8">{remoteParticipants}</div>
        </div>
      </div>
      {/* small screen leave button */}
      <button
        onClick={() => {
          window.location.href = `/${i18n.language}/roomvideo/rating`;
          handleLogout();
        }}
        className="block lg:hidden ml-24 mb-5 h-12 w-48 bg-red-600 rounded font-semibold text-sm text-white hover:bg-red-700"
      >
        End Call
      </button>
      {/* large screen leave button */}
      <button
        onClick={() => {
          window.location.href = `/${i18n.language}/roomvideo/rating`;
          handleLogout();
        }}
        className="hidden lg:block h-12 w-64 mx-auto bg-red-600 rounded font-semibold text-sm text-white hover:bg-red-700"
      >
        End Call
      </button>
      {/* use it when we use multipule remote participants */}
      {/* <div className="remote-participants flex flex-nowrap justify-between p-8"> 
        {remoteParticipants}
      </div> */}
    </div>
  );
};

export default Room;
