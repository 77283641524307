import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect
} from 'react-router-dom';
import Loading from 'src/pages/Loader/Loader';
import React, { Suspense, lazy } from 'react';
import TwilioVideo from 'src/pages/TwilioVideo';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
// import { ServiceDetails } from 'src/pages/ServiceDetails';
// import { WeddingOfTheWeekDetails } from 'src/pages/PostDetails/WeddingOfTheWeekDetails';
// import { PhotoDetails } from 'src/pages/PhotoDetails';
// import SignUpForm from 'src/pages/LoginAccount/SignUpForm';
// import Login from 'src/pages/LoginAccount/Login';

// import { AppointmentForm } from 'src/pages/ServiceDetails/AppointmentForm';

// import { VendorList } from 'src/pages/Vendor/VendorList';
// import { TipsAndEtiquetteDetails } from 'src/pages/PostDetails/TipsAndEtiquetteDetails';
// import { TipsAndEtiqutte } from 'src/pages/Posts/TipsAndEtiqutte';
// import { TagsFilter } from 'src/pages/Posts/TagsFilter';
// import { AllServicesVendros } from 'src/pages/AllVendorsServices';
// import { VendorServicesList } from 'src/pages/Vendor/VendorServicesList';
// import VendorContainer from 'src/pages/Vendor/VendorDetails/VendorContainer';
// import { UserPayment } from 'src/pages/ServiceDetails/UserPayment';
// import UserContainer from 'src/pages/UserProfile/userContainer';
// import { MoreWeddings } from 'src/pages/MorePages/MoreWeddings';
// import { MoreHer } from 'src/pages/MorePages/MoreHer';
// import { MoreHim } from 'src/pages/MorePages/MoreHim';
// import MoreGallery from 'src/pages/MorePages/MoreGallery/MoreGallery';
// import SetPassword from 'src/pages/LoginAccount/SetPassword';
// import { ResetPassword } from 'src/pages/LoginAccount/ResetPassword';
// import { Going } from 'src/pages/RsvpResponse/Going';
// import { Declined } from 'src/pages/RsvpResponse/Declined';
// import { PaymentSuccess } from 'src/pages/Vendor/VendorDetails/VendorSubscription/PaymentSuccess';

// import MoreIdeas from 'src/pages/MorePages/MoreIdeas/MoreIdeas';
// import MakeReview from 'src/common/Review';
// import PrivacyPolicy from 'src/pages/Privacy and Policy';
import Layout from '../common/Layout';
// import Home from '../pages/Home';

// import Checklist from '../pages/360Planner/Checklist';

// import Budgeter from '../pages/360Planner/Budgeter';
// import RegistryList from '../pages/360Planner/RegistryList';
// import GuestList from '../pages/360Planner/GuestList';
// import WeddingWebsite from '../pages/WeddingWebsite/index';
// import CoupleWebsite from '../pages/360Planner/CoupleWebsite';
// import PlannerMore from '../pages/360Planner/PlannerPage';
// import { Services } from '../pages/Services';
// import { WeddingOfTheWeek } from '../pages/Posts/WeddingOfTheWeek';
// import { Photos } from '../pages/Photos';
// import Forgotpassword from '../pages/LoginAccount/ForgotPassword';
// import ReceiveConfirmation from '../pages/LoginAccount/ReceiveConfirmation';
// import { GuestRegistryList } from '../pages/360Planner/RegistryList/guest';
// import Container from '../pages/360PlannerNew/Container';
import './App.css';
// import TermsAndConditions from '../pages/TermsAndConditions/index';
const TermsAndConditions = lazy(
  () => import('../pages/TermsAndConditions/index')
);

const Container = lazy(() => import('../pages/360PlannerNew/Container'));
// const Home = lazy(() => import('../pages/Home/index'));
const ServiceDetails = lazy(() => import('../pages/ServiceDetails/index'));
const WeddingOfTheWeekDetails = lazy(
  () => import('../pages/PostDetails/WeddingOfTheWeekDetails/index')
);
const PhotoDetails = lazy(() => import('../pages/PhotoDetails/index'));
const SignUpForm = lazy(() => import('../pages/LoginAccount/SignUpForm/index'));
const Login = lazy(() => import('../pages/LoginAccount/Login'));
const Forgotpassword = lazy(
  () => import('../pages/LoginAccount/ForgotPassword')
);
const AppointmentForm = lazy(
  () => import('../pages/ServiceDetails/AppointmentForm/index')
);
const VendorList = lazy(() => import('../pages/Vendor/VendorList/index'));
const TipsAndEtiquetteDetails = lazy(
  () => import('../pages/PostDetails/TipsAndEtiquetteDetails/index')
);
const TipsAndEtiqutte = lazy(
  () => import('../pages/Posts/TipsAndEtiqutte/index')
);
const TagsFilter = lazy(() => import('../pages/Posts/TagsFilter/index'));
const AllServicesVendros = lazy(
  () => import('../pages/AllVendorsServices/index')
);
const VendorServicesList = lazy(
  () => import('../pages/Vendor/VendorServicesList/index')
);
const VendorContainer = lazy(
  () => import('../pages/Vendor/VendorDetails/VendorContainer')
);
const UserPayment = lazy(
  () => import('../pages/ServiceDetails/UserPayment/index')
);
const UserContainer = lazy(() => import('../pages/UserProfile/userContainer'));
const MoreWeddings = lazy(
  () => import('../pages/MorePages/MoreWeddings/index')
);
const MoreHer = lazy(() => import('../pages/MorePages/MoreHer/index'));
const MoreHim = lazy(() => import('../pages/MorePages/MoreHim/index'));
const WeddingWebsite = lazy(() => import('../pages/WeddingWebsite/index'));

const MoreGallery = lazy(
  () => import('../pages/MorePages/MoreGallery/MoreGallery')
);
const SetPassword = lazy(() => import('../pages/LoginAccount/SetPassword'));
const ResetPassword = lazy(() => import('../pages/LoginAccount/ResetPassword'));
const ReceiveConfirmation = lazy(
  () => import('../pages/LoginAccount/ReceiveConfirmation')
);
const Going = lazy(() => import('../pages/RsvpResponse/Going/index'));
const Declined = lazy(() => import('../pages/RsvpResponse/Declined/index'));
const PaymentSuccess = lazy(
  () =>
    import(
      '../pages/Vendor/VendorDetails/VendorSubscription/PaymentSuccess/index'
    )
);
const MoreIdeas = lazy(() => import('../pages/MorePages/MoreIdeas/MoreIdeas'));
const PrivacyPolicy = lazy(() => import('../pages/Privacy and Policy/index'));
const Checklist = lazy(() => import('../pages/360Planner/Checklist/index'));
const Budgeter = lazy(() => import('../pages/360Planner/Budgeter/index'));
const CoupleWebsite = lazy(
  () => import('../pages/360Planner/CoupleWebsite/index')
);
const PlannerMore = lazy(() => import('../pages/360Planner/PlannerPage/index'));
const GuestRegistryList = lazy(
  () => import('../pages/360Planner/RegistryList/guest/index')
);
const RegistryList = lazy(
  () => import('../pages/360Planner/RegistryList/index')
);
const GuestList = lazy(() => import('../pages/360Planner/GuestList/index'));
const MakeReview = lazy(() => import('../common/Review/index'));
const Services = lazy(() => import('../pages/Services/index'));
const WeddingOfTheWeek = lazy(
  () => import('../pages/Posts/WeddingOfTheWeek/index')
);
const Photos = lazy(() => import('../pages/Photos/index'));
const error404 = lazy(() => import('../pages/Error/404/index'));
// const Layout = lazy(() => import('../common/Layout/index'));

const Wire = lazy(() => import('../pages/wire/index'));

const Search = lazy(() => import('../pages/Search/index'));

const userEmail = localStorage.getItem('email');

function App(): JSX.Element {
  const { i18n } = useTranslation();
  return (
    <div className={i18n.language === 'en' ? 'en-font' : 'ar-font'}>
      {userEmail ? (
        <Router>
          <Helmet
            htmlAttributes={{
              lang: i18n.language,
              dir: i18n.dir()
            }}
          />
          <Layout>
            <Suspense fallback={<Loading />}>
              <Switch>
                {' '}
                <Route exact path="/:lang(en|ar)" component={Wire} />
                <Route exact path="/:lang(en|ar)/search" component={Search} />
                <Route exact path="/" component={Wire} />
                {/* 360 Planner */}
                <Route
                  exact
                  path="/:lang(en|ar)/360Planner/checklist"
                  component={Checklist}
                />
                <Route exact path="/:lang(en|ar)/wire" component={Wire} />
                <Route
                  exact
                  path="/:lang(en|ar)/360Planner/budgeter"
                  component={Budgeter}
                />
                <Route
                  exact
                  path="/:lang(en|ar)/360Planner/registrylist"
                  component={RegistryList}
                />
                <Route
                  exact
                  path="/:lang(en|ar)/moreGallery/"
                  component={MoreGallery}
                />
                <Route
                  exact
                  path="/:lang(en|ar)/moreIdeas/"
                  component={MoreIdeas}
                />
                <Route
                  exact
                  path="/:lang(en|ar)/360Planner/registrylist/guest/:eventid/:weddingid"
                  component={GuestRegistryList}
                />
                <Route
                  exact
                  path="/:lang(en|ar)/360Planner/guestlist"
                  component={GuestList}
                />
                <Route
                  exact
                  path="/:lang(en|ar)/weddingWebsite/:routeURL"
                  component={WeddingWebsite}
                />
                <Route
                  exact
                  path="/:lang(en|ar)/360Planner/couple"
                  component={CoupleWebsite}
                />
                <Route
                  exact
                  path="/:lang(en|ar)/360Planner"
                  component={PlannerMore}
                />
                <Route
                  exact
                  path="/:lang(en|ar)/service/:id/:name"
                  component={ServiceDetails}
                />
                <Route
                  exact
                  path="/:lang(en|ar)/payment/:id"
                  component={UserPayment}
                />
                <Route exact path="/services/:id" component={ServiceDetails} />
                <Route
                  exact
                  path="/ar/services/:id"
                  component={ServiceDetails}
                />
                <Route
                  exact
                  path="/en/services/:id"
                  component={ServiceDetails}
                />
                <Route
                  exact
                  path="/:lang(en|ar)/services/category/:id"
                  component={Services}
                />
                <Route
                  exact
                  path="/:lang(en|ar)/allVendorsServices"
                  component={AllServicesVendros}
                />
                <Route
                  exact
                  path="/:lang(en|ar)/WeddingOfTheWeek/:id/:name"
                  component={WeddingOfTheWeekDetails}
                />
                <Route
                  exact
                  path="/:lang(en|ar)/WeddingOfTheWeek/"
                  component={WeddingOfTheWeek}
                />
                <Route
                  exact
                  path="/:lang(en|ar)/TipsAndAdvice/:id/:name"
                  component={TipsAndEtiquetteDetails}
                />
                <Route
                  exact
                  path="/:lang(en|ar)/TipsAndAdvice/"
                  component={TipsAndEtiqutte}
                />
                <Route
                  exact
                  path="/:lang(en|ar)/tags/:tagName"
                  component={TagsFilter}
                />
                <Route
                  exact
                  path="/:lang(en|ar)/photo/:id/:name"
                  component={PhotoDetails}
                />
                <Route
                  exact
                  path="/:lang(en|ar)/SignUp/"
                  component={SignUpForm}
                />
                <Route exact path="/:lang(en|ar)/Login/" component={Login} />
                <Route
                  exact
                  path="/:lang(en|ar)/security/reset-password/:resetToken/:emailToken"
                  component={ResetPassword}
                />
                <Route
                  exact
                  path="/:lang(en|ar)/setPassword/"
                  component={SetPassword}
                />
                <Route
                  exact
                  path="/:lang(en|ar)/ForgotPassword/"
                  component={Forgotpassword}
                />
                <Route
                  exact
                  path="/:lang(en|ar)/ReceiveConfimation/"
                  component={ReceiveConfirmation}
                />
                <Route
                  exact
                  path="/:lang(en|ar)/PrivacyPolicy"
                  component={PrivacyPolicy}
                />
                <Route
                  exact
                  path="/:lang(en|ar)/TermsAndConditions"
                  component={TermsAndConditions}
                />
                <Route path="/:lang(en|ar)/planner" component={Container} />
                <Route path="/:lang(en|ar)/user" component={UserContainer} />
                <Route exact path="/:lang(en|ar)/photos/" component={Photos} />
                <Route
                  exact
                  path="/:lang(en|ar)/moreWeddings/"
                  component={MoreWeddings}
                />
                <Route
                  exact
                  path="/:lang(en|ar)/moreHer/"
                  component={MoreHer}
                />
                <Route
                  exact
                  path="/:lang(en|ar)/moreHim/"
                  component={MoreHim}
                />
                <Route
                  exact
                  path="/:lang(en|ar)/rsvp/declined"
                  component={Declined}
                />
                <Route
                  exact
                  path="/:lang(en|ar)/rsvp/going"
                  component={Going}
                />
                <Route
                  exact
                  path="/:lang(en|ar)/twilio/:roomName"
                  component={TwilioVideo}
                />
                <Route
                  exact
                  path="/:lang(en|ar)/roomvideo/rating"
                  component={MakeReview}
                />
                <Route
                  exact
                  path="/:lang(en|ar)/appointmentForm/:id"
                  component={AppointmentForm}
                />
                <Route
                  exact
                  path="/:lang(en|ar)/vendorServices/:id"
                  component={VendorServicesList}
                />
                <Route
                  path="/:lang(en|ar)/vendor"
                  component={VendorContainer}
                />
                <Route
                  exact
                  path="/:lang(en|ar)/vendorList/"
                  component={VendorList}
                />
                <Route
                  exact
                  path="/:lang(en|ar)/paymentStatus"
                  component={PaymentSuccess}
                />
                <Route exact path="/:lang(en|ar)/404" component={error404} />
                <Route>
                  <Redirect to={`/${i18n.language}/404`} />
                </Route>
              </Switch>
            </Suspense>
          </Layout>
        </Router>
      ) : (
        <Router>
          <Helmet
            htmlAttributes={{
              lang: i18n.language,
              dir: i18n.dir()
            }}
          />
          <Layout>
            <Suspense fallback={<Loading />}>
              <Switch>
                {' '}
                <Route exact path="/:lang(en|ar)" component={Wire} />
                <Route exact path="/:lang(en|ar)/search" component={Search} />
                <Route exact path="/:lang(en|ar)/wire" component={Wire} />
                <Route exact path="/" component={Wire} />
                {/* 360 Planner */}
                <Route
                  exact
                  // path="/:lang(en|ar)/360Planner/checklist"
                  path={`/${i18n.language}/360Planner/checklist`}
                  component={Checklist}
                />
                <Route
                  exact
                  path="/:lang(en|ar)/360Planner/budgeter"
                  component={Budgeter}
                />
                <Route
                  exact
                  path="/:lang(en|ar)/360Planner/registrylist"
                  component={RegistryList}
                />
                <Route
                  exact
                  path="/:lang(en|ar)/360Planner/registrylist/guest/:eventid/:weddingid"
                  component={GuestRegistryList}
                />
                <Route
                  exact
                  path="/:lang(en|ar)/360Planner/guestlist"
                  component={GuestList}
                />
                <Route
                  exact
                  path="/:lang(en|ar)/weddingWebsite/:routeURL"
                  component={WeddingWebsite}
                />
                <Route
                  exact
                  path="/:lang(en|ar)/360Planner/couple"
                  component={CoupleWebsite}
                />
                <Route
                  exact
                  path="/:lang(en|ar)/360Planner"
                  component={PlannerMore}
                />
                <Route
                  exact
                  path="/:lang(en|ar)/service/:id/:name"
                  component={ServiceDetails}
                />
                <Route exact path="/services/:id" component={ServiceDetails} />
                <Route
                  exact
                  path="/en/services/:id"
                  component={ServiceDetails}
                />
                <Route
                  exact
                  path="/ar/services/:id"
                  component={ServiceDetails}
                />
                <Route
                  exact
                  path="/:lang(en|ar)/services/category/:id"
                  component={Services}
                />
                <Route
                  exact
                  path="/:lang(en|ar)/allVendorsServices"
                  component={AllServicesVendros}
                />
                <Route
                  exact
                  path="/:lang(en|ar)/WeddingOfTheWeek/:id/:name"
                  component={WeddingOfTheWeekDetails}
                />
                <Route
                  exact
                  path="/:lang(en|ar)/WeddingOfTheWeek/"
                  component={WeddingOfTheWeek}
                />{' '}
                <Route
                  exact
                  path="/:lang(en|ar)/moreGallery/"
                  component={MoreGallery}
                />
                <Route
                  exact
                  path="/:lang(en|ar)/moreIdeas/"
                  component={MoreIdeas}
                />
                <Route
                  exact
                  path="/:lang(en|ar)/TipsAndAdvice/:id/:name"
                  component={TipsAndEtiquetteDetails}
                />
                <Route
                  exact
                  path="/:lang(en|ar)/TipsAndAdvice/"
                  component={TipsAndEtiqutte}
                />
                <Route
                  exact
                  path="/:lang(en|ar)/tags/:tagName"
                  component={TagsFilter}
                />
                <Route
                  exact
                  path="/:lang(en|ar)/photo/:id/:name"
                  component={PhotoDetails}
                />
                <Route
                  exact
                  path="/:lang(en|ar)/SignUp/"
                  component={SignUpForm}
                />
                <Route exact path="/:lang(en|ar)/Login/" component={Login} />
                <Route
                  exact
                  path="/:lang(en|ar)/setPassword/"
                  component={SetPassword}
                />
                <Route
                  exact
                  path="/:lang(en|ar)/ForgotPassword/"
                  component={Forgotpassword}
                />
                <Route
                  exact
                  path="/:lang(en|ar)/security/reset-password/:resetToken/:emailToken"
                  component={ResetPassword}
                />
                <Route
                  exact
                  path="/:lang(en|ar)/ReceiveConfimation/"
                  component={ReceiveConfirmation}
                />
                {/* <Route path="/:lang(en|ar)/planner" component={Container} />
              <Route path="/:lang(en|ar)/vendor" component={VendorContainer} /> */}
                <Route exact path="/:lang(en|ar)/photos/" component={Photos} />
                <Route
                  exact
                  path="/:lang(en|ar)/moreWeddings/"
                  component={MoreWeddings}
                />
                <Route
                  exact
                  path="/:lang(en|ar)/moreHer/"
                  component={MoreHer}
                />
                <Route
                  exact
                  path="/:lang(en|ar)/moreHim/"
                  component={MoreHim}
                />
                <Route
                  exact
                  path="/:lang(en|ar)/moreGallery/"
                  component={MoreGallery}
                />
                <Route
                  exact
                  path="/:lang(en|ar)/moreIdeas/"
                  component={MoreIdeas}
                />
                <Route
                  exact
                  path="/:lang(en|ar)/twilio/:roomName"
                  component={TwilioVideo}
                />
                <Route
                  exact
                  path="/:lang(en|ar)/roomvideo/rating"
                  component={MakeReview}
                />
                <Route
                  exact
                  path="/:lang(en|ar)/appointmentForm/:id"
                  component={AppointmentForm}
                />
                <Route
                  exact
                  path="/:lang(en|ar)/PrivacyPolicy"
                  component={PrivacyPolicy}
                />
                <Route
                  exact
                  path="/:lang(en|ar)/TermsAndConditions"
                  component={TermsAndConditions}
                />
                <Route
                  exact
                  path="/:lang(en|ar)/vendorServices/:id"
                  component={VendorServicesList}
                />
                <Route
                  exact
                  path="/:lang(en|ar)/rsvp/declined"
                  component={Declined}
                />
                <Route
                  exact
                  path="/:lang(en|ar)/rsvp/going"
                  component={Going}
                />
                <Route exact path="/:lang(en|ar)/404" component={error404} />
                <Route>
                  <Redirect to={`/${i18n.language}/404`} />
                </Route>
              </Switch>
            </Suspense>
          </Layout>
        </Router>
      )}
    </div>
  );
}

export default App;
