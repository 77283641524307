/* eslint-disable no-restricted-globals */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { useTranslation } from 'react-i18next';
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useRecoilState } from 'recoil';

import { i18n } from 'src/i18n';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faBirthdayCake,
  faArrowLeft,
  faArrowRight
} from '@fortawesome/free-solid-svg-icons';
import {
  authTokenState,
  LoggedInState
} from '../../../recoilResources/User.Atoms';
import { SignInFacebook } from './SignInFacebook';
import { SignInGoogle } from './SignInGoogle';
import { HandleLogin } from './HandleLogin';

interface TwilioVideo {
  isTwilioVideo?: boolean;
  roomName?: string;
}

export default function Form({
  isTwilioVideo,
  roomName
}: TwilioVideo): JSX.Element {
  const { t } = useTranslation();

  const [authToken, setAuthToken] = useRecoilState(authTokenState);
  const [login, setLogin] = useRecoilState(LoggedInState);
  const [emailInput, setEmailInput] = useState('');
  const [socialLoginErrorMessage, setSocialLoginErrorMessage] = useState('');

  const [checkbox, setCheckbox] = useState('');

  const [plannerMessage, setPlannerMessage] = useState<boolean>(false);
  const [isVendor, setIsVendor] = useState(false);
  const [phoneInput, setPhoneInput] = useState('');

  const [allOptions, setAllOptions] = useState<boolean>(false);

  useEffect(() => {
    const fetchData = async () => {
      window.scroll(0, 0);
      if (window.location.search === `?internalRedirect=true`) {
        setPlannerMessage(true);
      } else {
        setPlannerMessage(false);
      }
    };
    fetchData();
  }, []);

  const emailInputHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmailInput(e.currentTarget.value.toLowerCase());
  };

  const phoneInputHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPhoneInput(e.currentTarget.value);
  };

  const checkboxHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCheckbox(e.currentTarget.value);

    if (e.currentTarget.value === `VENDOR`) {
      setIsVendor(true);
    }
  };
  const border = innerWidth > 768 ? '1px solid rgba(0, 0, 0, 0.25)' : ``;

  return (
    <section className="px-5">
      <div
        style={{
          border,
          borderRadius: '35px'
        }}
        className="mx-auto text-center sm:text-left w-full lg:w-6/12 sm:border-2 p-8 relative top-10 mb-3 sm:mb-32"
      >
        {isTwilioVideo ? (
          <h1 className="text-2xl font-bold mb-5 text-green-700">
            Please Sign in to join the call
          </h1>
        ) : (
          ``
        )}
        <div
          className={
            i18n.language === 'en'
              ? ' sm:text-left py-2'
              : ' sm:text-right py-2'
          }
        >
          <header className="text-black font-bold text-2xl mb-2">
            {t('sign_in.sign_in_now')}
          </header>
          {t('sign_in.sign_in_para')}
          {'  '}
          <FontAwesomeIcon style={{ color: '#DF8A40' }} icon={faBirthdayCake} />
        </div>
        {plannerMessage ? (
          <div className="p-5 bg-red-300 rounded text-left mx-auto mt-5">
            {t('sign_in.sign_in_planner_message')}
          </div>
        ) : (
          ``
        )}
        {allOptions ? (
          <>
            <HandleLogin
              emailInput={emailInput}
              emailInputHandler={emailInputHandler}
              isTwilioVideo={isTwilioVideo}
              roomName={roomName}
              setLogin={setLogin}
              setAuthToken={setAuthToken}
            />
            <div className="py-2 text-center">
              <button
                className="w-full md:w-1/2 rounded-3xl p-5 pb-4 text-black text-md mt-3 lg:mt-0 lg:text-lg font-bold bg-gray-300 uppercase"
                onClick={() => setAllOptions(false)}
              >
                <FontAwesomeIcon
                  className="mx-2"
                  style={{ color: 'black' }}
                  icon={i18n.language === 'en' ? faArrowLeft : faArrowRight}
                />
                All sign in options
              </button>
            </div>
          </>
        ) : (
          ``
        )}

        {!allOptions ? (
          <>
            <div className="flex flex-col gap-4 w-full md:w-1/2 items-center mx-auto">
              <SignInFacebook
                checkboxHandler={checkboxHandler}
                checkbox={checkbox}
                isVendor={isVendor}
                phoneInput={phoneInput}
                phoneInputHandler={phoneInputHandler}
                isTwilioVideo={isTwilioVideo}
                roomName={roomName}
                setLogin={setLogin}
                setAuthToken={setAuthToken}
                setSocialLoginErrorMessage={setSocialLoginErrorMessage}
              />
              <SignInGoogle
                checkboxHandler={checkboxHandler}
                checkbox={checkbox}
                isVendor={isVendor}
                phoneInput={phoneInput}
                phoneInputHandler={phoneInputHandler}
                isTwilioVideo={isTwilioVideo}
                roomName={roomName}
                setLogin={setLogin}
                setAuthToken={setAuthToken}
                setSocialLoginErrorMessage={setSocialLoginErrorMessage}
              />
            </div>
            <div className="py-2 text-center">
              <button
                className="w-full md:w-1/2 rounded-3xl p-5 pb-4 text-black text-md mt-3 lg:mt-0 lg:text-lg font-bold bg-gray-300 uppercase"
                onClick={() => setAllOptions(true)}
              >
                sign in with Email
              </button>
            </div>
          </>
        ) : (
          ``
        )}

        {socialLoginErrorMessage !== `` ? (
          <div className="mt-2 text-xl text-red-700 p-2 w-full md:w-2/3 mx-auto">
            {socialLoginErrorMessage}
          </div>
        ) : (
          ``
        )}
        <div className="flex flex-col sm:flex-row gap-1 justify-center mt-6 items-center">
          <p style={{ color: '#9D9FA0' }}>{t('sign_in.sign_in_noacc')}</p>

          <Link
            style={{ color: '#DF8A40' }}
            to="/en/SignUp"
            className="text-md font-semibold"
          >
            {t('sign_in.sign_in_sign_up')}
          </Link>
        </div>
      </div>
    </section>
  );
}
