/* eslint-disable no-console */
/* eslint-disable no-unneeded-ternary */
import { useState, useEffect } from 'react';
import { TierOneGrid } from 'src/pages/ServiceDetails/TierOneGrid';
import { VendorService } from 'src/services/vendors.service';
import { useTranslation } from 'react-i18next';
import {
  getSessionStorage,
  setSessionStorage
} from 'src/utils/sessionStorageHelper';
import { SESSION_TIER_A_KEY } from 'src/utils/storageKeys';
import { i18n } from 'src/i18n';

// interface Tier {
//   _id: string;

//   featuredImage: string;
//   nameEn: string;
//   descriptionEn: string;

//   vendorNameEn?: string;
// }

const tierAarray = [
  {
    _id: '#',
    featuredImage: 'https://s3.eu-west-1.amazonaws.com/weds360.com/logo.png',
    nameEn: 'Advertise Here',
    nameAr: 'اعلن هنا',
    descriptionEn: '',
    vendorNameEn: 'Advertise Here',
    vendorNameAr: 'اعلن هنا'
  },
  {
    _id: '#',
    featuredImage: 'https://s3.eu-west-1.amazonaws.com/weds360.com/logo.png',
    nameEn: 'Advertise Here',
    nameAr: 'اعلن هنا',
    descriptionEn: '',
    vendorNameEn: 'Advertise Here',
    vendorNameAr: 'اعلن هنا'
  },
  {
    _id: '#',
    featuredImage: 'https://s3.eu-west-1.amazonaws.com/weds360.com/logo.png',
    nameEn: 'Advertise Here',
    nameAr: 'اعلن هنا',
    descriptionEn: '',
    vendorNameEn: 'Advertise Here',
    vendorNameAr: 'اعلن هنا'
  },
  {
    _id: '#',
    featuredImage: 'https://s3.eu-west-1.amazonaws.com/weds360.com/logo.png',
    nameEn: 'Advertise Here',
    nameAr: 'اعلن هنا',
    descriptionEn: '',
    vendorNameEn: 'Advertise Here',
    vendorNameAr: 'اعلن هنا'
  },
  {
    _id: '#',
    featuredImage: 'https://s3.eu-west-1.amazonaws.com/weds360.com/logo.png',
    nameEn: 'Advertise Here',
    nameAr: 'اعلن هنا',
    descriptionEn: '',
    vendorNameEn: 'Advertise Here',
    vendorNameAr: 'اعلن هنا'
  }
];
export const TierA = (): JSX.Element => {
  const { t } = useTranslation();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [featuredServiceTierA, setFeaturedServiceTierA] = useState<any>();

  useEffect(() => {
    const fetchData = async () => {
      if (getSessionStorage(SESSION_TIER_A_KEY) === null) {
        const tierAResults = await VendorService.getTierAFeaturedServices(`A`);
        setSessionStorage(SESSION_TIER_A_KEY, JSON.stringify(tierAResults));
        setFeaturedServiceTierA(
          tierAResults.length !== 0 ? tierAResults : tierAarray
        );
        // todo find a way to set state using only one call
      } else {
        const data = JSON.parse(getSessionStorage(SESSION_TIER_A_KEY));
        setFeaturedServiceTierA(data.length !== 0 ? data : tierAarray);
      }
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n.language]);

  return (
    <div className="py-5">
      <div className="w-full lg:w-9/12 mx-auto mt-6 mb-6" />
      <h4 className="text-2xl lg:text-4xl font-bold text-center mb-2 ">
        {t('services.featured_vendors')}
      </h4>
      <div
        className="mx-auto w-full lg:w-8/12 py-5 px-5 overflow-hidden"
        style={{ direction: 'ltr' }}
      >
        {featuredServiceTierA !== undefined ? (
          <TierOneGrid services={featuredServiceTierA} />
        ) : (
          ``
        )}
      </div>
    </div>
  );
};
