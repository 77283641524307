/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { GoogleLogin } from 'react-google-login';

import { UsersService } from 'src/services/users.service';
import Modal from 'react-modal';
import Button from 'src/common/Button/index';
import { i18n } from 'src/i18n';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)'
  }
};

type GoogleProps = {
  checkboxHandler: any;
  checkbox: any;
  isVendor: boolean;
  phoneInput: any;
  phoneInputHandler: any;
  isTwilioVideo: any;
  roomName: any;
  setLogin: any;
  setAuthToken: any;
  setSocialLoginErrorMessage: any;
};

export const SignInGoogle = ({
  checkboxHandler,
  checkbox,
  isVendor,
  phoneInput,
  phoneInputHandler,
  isTwilioVideo,
  roomName,
  setLogin,
  setAuthToken,
  setSocialLoginErrorMessage
}: GoogleProps): JSX.Element => {
  const { t } = useTranslation();

  const [markedGoogle, setMarkedGoogle] = useState(false);
  const [googleEmail, setGoogleEmail] = useState('');
  const [googleName, setGoogleName] = useState('');
  const [googlePhoto, setGooglePhoto] = useState('');
  const [googleToken, setGoogleToken] = useState('');
  const [invitationCode, setInvitationCode] = useState('');

  const referralCodeInputHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInvitationCode(e.currentTarget.value);
  };

  let googleResult: any;
  let logged: any;
  const responseGoogle = async (response: any) => {
    let user;
    setGoogleEmail(response?.profileObj?.email);
    setGoogleName(response?.profileObj?.name);
    setGooglePhoto(response.profileObj?.imageUrl);
    setGoogleToken(response?.tokenId);
    const userExists: any = await UsersService.getUserByEmail(
      response?.profileObj?.email
    );

    if (userExists.data === null) setMarkedGoogle(true);
    else {
      user = {
        email: userExists?.data?.email
      };
      googleResult = await UsersService.userSignInFaceBook(user);
    }
    if (
      response?.profileObj?.email === `` ||
      response?.profileObj?.email === undefined
    ) {
      setSocialLoginErrorMessage(
        `You can not SignUp or Login without your email`
      );
      setMarkedGoogle(false);
    } else {
      const vendorId = googleResult?.data?.user?.vendorRefrence;
      const returnToken = googleResult?.data?.token;
      const userID = googleResult?.data?.user?._id;
      const role = googleResult?.data?.user?.role;
      const referralCode = userExists?.data?.referralCode || ``;
      const invitationsCounter = userExists?.data?.invitationsCounter || 0;
      const userPoints = userExists?.data?.points || 0;

      localStorage.setItem('avatar', userExists?.data?.settings?.avatar);
      localStorage.setItem('email', userExists?.data?.email);
      localStorage.setItem('vendorId', vendorId);
      localStorage.setItem('authToken', returnToken);
      localStorage.setItem('name', userExists?.data?.name);
      localStorage.setItem('userID', userID);
      localStorage.setItem('role', role);
      setAuthToken(returnToken);
      setLogin(true);
      localStorage.setItem('userlogged', 'yes');
      localStorage.setItem('googleUser', 'yes');
      localStorage.setItem('loggedIn', 'true');
      localStorage.setItem('referralCode', referralCode);
      localStorage.setItem('invitationsCounter', invitationsCounter);
      localStorage.setItem('userPoints', userPoints);

      logged = localStorage.getItem('loggedIn');
      if (
        (logged === 'true' && role === 'USER' && isTwilioVideo) ||
        (logged === 'true' && role === 'VENDOR' && isTwilioVideo)
      ) {
        window.location.href = `/${i18n.language}/twilio/${roomName}`;
      } else if (logged === 'true' && role === 'USER') {
        window.location.href = `/${i18n.language}/planner/checklist`;
      } else if (logged === 'true' && role === 'VENDOR') {
        window.location.href = `/${i18n.language}/vendor/serviceCreation`;
      }
    }
  };

  const googleLoggedIn = async () => {
    let user;
    if (isVendor) {
      user = {
        email: googleEmail,
        name: googleName,
        role: checkbox,
        accountSource: 'GOOGLE',
        token: googleToken,
        phone: phoneInput,
        invitationCode: invitationCode || ``,
        settings: {
          avatar: googlePhoto
        },
        policyApproved: true
      };
    } else {
      user = {
        email: googleEmail,
        name: googleName,
        role: checkbox,
        accountSource: 'GOOGLE',
        token: googleToken,
        invitationCode: invitationCode || ``,
        settings: {
          avatar: googlePhoto
        },
        policyApproved: true
      };
    }

    googleResult = await UsersService.userSignInFaceBook(user);
    const vendorId = googleResult?.data?.user?.vendorRefrence;
    const returnToken = googleResult?.data?.token;
    const userID = googleResult?.data?.user?._id;
    const role = googleResult?.data?.user?.role;

    localStorage.setItem('avatar', googlePhoto);
    localStorage.setItem('email', googleEmail);
    localStorage.setItem('vendorId', vendorId);
    localStorage.setItem('authToken', returnToken);
    localStorage.setItem('name', googleName);
    localStorage.setItem('userID', userID);
    localStorage.setItem('role', role);
    setAuthToken(returnToken);
    setLogin(true);
    localStorage.setItem('userlogged', 'yes');
    localStorage.setItem('googleUser', 'yes');
    localStorage.setItem('loggedIn', 'true');
    logged = localStorage.getItem('loggedIn');
    if (
      (logged === 'true' && role === 'USER' && isTwilioVideo) ||
      (logged === 'true' && role === 'VENDOR' && isTwilioVideo)
    ) {
      window.location.href = `/${i18n.language}/twilio/${roomName}`;
    } else if (logged === 'true' && role === 'USER') {
      window.location.href = `/${i18n.language}/planner/checklist`;
    } else if (logged === 'true' && role === 'VENDOR') {
      window.location.href = `/${i18n.language}/vendor/serviceCreation`;
    }
  };
  return (
    <div className="w-full">
      <GoogleLogin
        clientId="892968088547-hab3jb8djcb236t3s69877tmmgu0hfa1.apps.googleusercontent.com"
        render={(renderProps) => (
          <button
            className="w-full rounded-3xl p-5 pb-4 text-black text-md mt-3 lg:mt-0 lg:text-lg font-bold "
            onClick={renderProps.onClick}
            disabled={renderProps.disabled}
            style={{
              backgroundColor: '#EEEEEE'
            }}
          >
            <i className="fab fa-google-plus-g bg-white text-red-500 rounded-full p-1 h-6 " />
            {t('sign_in.sign_in_gmail')}
          </button>
        )}
        buttonText="Login"
        onSuccess={responseGoogle}
        cookiePolicy="single_host_origin"
      />
      <div className="w-full">
        {markedGoogle ? (
          <Modal isOpen={markedGoogle} style={customStyles}>
            <div className="text-black-600 text-2xl mx-auto ml-2 mt-2 uppercase">
              {t('login.socialRole')}
            </div>
            <div className=" flex justify-between mt-10 lg:w-full items-center md:w-full ">
              <div>
                {' '}
                <label className="lg:text-lg md:text-xs p-3" htmlFor="bride">
                  <input
                    className="mr-4"
                    name="registerType"
                    type="radio"
                    id="bride"
                    onChange={checkboxHandler}
                    value="USER"
                  />
                  {t('login.bride')}
                </label>
              </div>
              <div>
                {' '}
                <label className="lg:text-lg md:text-xs p-3" htmlFor="groom">
                  {' '}
                  <input
                    className="mr-4"
                    name="registerType"
                    type="radio"
                    id="groom"
                    onChange={checkboxHandler}
                    value="USER"
                  />
                  {t('login.groom')}
                </label>
              </div>
              <div>
                <label className="lg:text-lg md:text-xs p-3" htmlFor="vendor">
                  {' '}
                  <input
                    className="mr-4"
                    name="registerType"
                    type="radio"
                    id="vendor"
                    onChange={checkboxHandler}
                    value="VENDOR"
                  />
                  {t('login.vendor')}
                </label>
              </div>
            </div>
            {checkbox === 'USER' ? (
              <div className="mt-5">
                <label
                  className="lg:text-xl md:text-xs p-3 uppercase"
                  htmlFor="invitation"
                >
                  Invitation Code
                </label>
                <input
                  className="w-full border-none  transition duration-300 shadow-md mb-5  p-3 lg:p-5 bg-gray-100 placeholder-black text-center  focus:outline-none focus:ring focus:border-blue-300"
                  type="text"
                  // placeholder={t('sign_up.invitation_code')}  //when we add translation
                  placeholder="Invitation Code"
                  name="invitationCode"
                  value={invitationCode}
                  onChange={referralCodeInputHandler}
                />
              </div>
            ) : (
              ``
            )}
            <div>
              <Button
                text={t('login.choice')}
                onClickFunction={googleLoggedIn}
                css="mb-2 lg:mb-0 text-black text-lg lg:w-full bg-gray-50 border-2 border-black mt-5"
                typeofButton="reset"
              />
            </div>
            {isVendor ? (
              <Modal isOpen={isVendor} style={customStyles}>
                <div className="p-10 ">
                  <h1 className="mb-5 text-center text-2xl uppercase">
                    {t('login.phoneNumber')}
                  </h1>
                  <div>
                    <input
                      className="w-full border-none p-3 lg:px-32 mb-3 transition duration-300 shadow-md bg-gray-100 placeholder-black text-center  focus:outline-none focus:ring focus:border-blue-300 mb-5"
                      type="phone"
                      placeholder="phone"
                      name="phone"
                      value={phoneInput}
                      onChange={phoneInputHandler}
                      required
                    />
                  </div>
                  <div className="mt-5">
                    <label
                      className="lg:text-xl md:text-xs p-3 uppercase"
                      htmlFor="invitation"
                    >
                      Invitation Code
                    </label>
                    <input
                      className="w-full border-none  transition duration-300 shadow-md mb-5  p-3 lg:p-5 bg-gray-100 placeholder-black text-center  focus:outline-none focus:ring focus:border-blue-300"
                      type="text"
                      // placeholder={t('sign_up.invitation_code')}  //when we add translation
                      placeholder="Invitation Code"
                      name="invitationCode"
                      value={invitationCode}
                      onChange={referralCodeInputHandler}
                    />
                  </div>
                  <div>
                    <Button
                      text={t('login.phoneButton')}
                      onClickFunction={googleLoggedIn}
                      css="mb-2 lg:mb-0 text-black text-lg lg:w-full bg-gray-50 border-2 border-black mt-5"
                      typeofButton="reset"
                    />
                  </div>
                </div>
              </Modal>
            ) : (
              ``
            )}
          </Modal>
        ) : (
          ``
        )}
      </div>
    </div>
  );
};
