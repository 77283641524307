/* eslint-disable no-restricted-globals */
/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

export const Footer = (): JSX.Element => {
  const { t, i18n } = useTranslation();
  const isClosed = localStorage.getItem('isClosed');
  const role = localStorage.getItem('role');
  return (
    <section
      style={{
        backgroundColor: role || isClosed ? `white` : `#126A6D`
      }}
    >
      <footer
        className="mx-auto px-4 sm:px-8 block bg-black text-white pt-2 relative text-sm"
        style={{
          height: `auto`,
          borderTopLeftRadius: `30px`,
          borderTopRightRadius: `30px`
        }}
      >
        <main className="block md:flex px-2 lg:px-20 pt-0 mt-3 lg:mt-0">
          <section className="w-full md:w-1/3">
            {/* <header className="font-semibold mb-2">
                {t('footer.header')}
              </header> */}
            <div className="block">
              <p className="flex-1 mb-3 text-gray-400">
                {t('footer.header_description_1')} <br />
                {t('footer.header_description_2')}
              </p>
              <ul className="flex gap-2 mt-2">
                <li>
                  <a
                    href={
                      role === 'VENDOR'
                        ? 'https://apps.apple.com/us/app/weds360-partners/id1437897443'
                        : 'https://apps.apple.com/us/app/weds360/id1437100880'
                    }
                    title="App Store"
                    className="flex-1 bg-white"
                  >
                    <img
                      src="https://weds360-production.s3.eu-west-1.amazonaws.com/new-weds360-preprod/PHOTOS/1644512613-Badge-1.png"
                      alt="Apple Store"
                    />
                  </a>
                </li>
                <li>
                  <a
                    href={
                      role === 'VENDOR'
                        ? 'https://play.google.com/store/apps/details?id=com.plus360.partners'
                        : 'https://play.google.com/store/apps/details?id=com.plus360.weds360'
                    }
                    title="Google Play"
                    className="flex-1"
                  >
                    <img
                      src="https://weds360-production.s3.eu-west-1.amazonaws.com/new-weds360-preprod/PHOTOS/1644512613-g4036.png"
                      alt="Google Play"
                    />
                  </a>
                </li>
                <li>
                  <a
                    href={
                      role === 'VENDOR'
                        ? '/'
                        : 'https://appgallery.huawei.com/app/C105048519'
                    }
                    title="huawei"
                    className="flex-1"
                  >
                    <img
                      src="https://weds360-production.s3.eu-west-1.amazonaws.com/new-weds360-preprod/PHOTOS/1644512613-Badge.png"
                      alt="Huawei Store"
                      className="max-h-10"
                    />
                  </a>
                </li>
              </ul>
            </div>
          </section>

          <section
            className={
              i18n.language === 'en'
                ? 'w-full md:w-2/3 md:flex ml-0 ml-0 md:ml-20'
                : 'w-full md:w-2/3 md:flex ml-0 mr-0 md:mr-20'
            }
          >
            <section className="w-full">
              <p
                className="font-semibold mb-2 sm:mb-2 mt-3 md:mt-0"
                style={{
                  color: `#808080`
                }}
              >
                {t('footer.support')}
              </p>
              <ul className="w-full">
                <li className="w-full my-2 font-light text-gray-200 hover:text-white">
                  <Link to={`/${i18n.language}/PrivacyPolicy`}>
                    {t('footer.privacy_policy')}
                  </Link>
                </li>
                <li className="w-full my-2 font-light text-gray-200 hover:text-white">
                  <Link to={`/${i18n.language}/TermsAndConditions`}>
                    {t('footer.terms_and_conditions')}
                  </Link>
                </li>
              </ul>
            </section>

            <section className="w-full">
              <div className="block mb-4 sm:mb-0">
                <header
                  className="font-semibold mb-2"
                  style={{
                    color: `#808080`
                  }}
                >
                  {t('footer.contact_us')}
                </header>
                <ul>
                  <li className="my-2">
                    <a
                      href="mailto:lovetohelp@weds360.com"
                      className="text-white "
                    >
                      <span className="font-bold">
                        {t('common.email')}
                        {`: `}
                      </span>
                      <span className="font-light text-gray-400 hover:text-white">
                        {' '}
                        {t('footer.email')}
                      </span>
                    </a>
                  </li>
                  <li className="my-2">
                    <a
                      href="https://www.google.com/maps/place/Weds360/@30.0554008,31.2084827,15z/data=!4m2!3m1!1s0x0:0x79c8f6df46489496?sa=X&ved=2ahUKEwjomur9_PT1AhWPhP0HHav1DfYQ_BJ6BAgsEAU"
                      className="text-white"
                      target="_blank"
                    >
                      <span className="font-bold">
                        {' '}
                        {t('footer.address')}: {` `}
                      </span>
                      <span className="font-light text-gray-400 hover:text-white">
                        {' '}
                        {t('footer.address_details')}
                      </span>
                    </a>
                  </li>
                  <li className="my-2">
                    <a href="tel:+201066776677" className="text-white">
                      <span className="font-bold">
                        {t('footer.phone_num')}
                        {`: `}
                      </span>
                      <span className="font-light text-gray-400 hover:text-white">
                        {' '}
                        {t('footer.phone')}
                      </span>
                    </a>
                  </li>
                </ul>
              </div>
            </section>
          </section>

          <div
            className={
              i18n.language === `en`
                ? 'sm:bottom-0 sm:right-0 sm:absolute p-5 md:mr-16'
                : 'sm:bottom-0 sm:left-0 sm:absolute p-5 md:ml-16'
            }
          >
            <ul className="flex justify-center sm:justify-end">
              <li>
                <a
                  href="https://www.facebook.com/Weds360/"
                  title="Facebook"
                  target="_blank"
                  className="flex-1 "
                >
                  <img
                    src="https://weds360-production.s3.eu-west-1.amazonaws.com/new-weds360-preprod/PHOTOS/1644512613-Facebook.png"
                    alt="Facebook"
                    className="w-auto h-auto"
                  />
                </a>
              </li>
              <li>
                <a
                  href="https://www.instagram.com/weds360/"
                  title="Instagram"
                  target="_blank"
                  className="flex-1"
                >
                  <img
                    src="https://weds360-production.s3.eu-west-1.amazonaws.com/new-weds360-preprod/PHOTOS/1644512613-Instagram%20%28filled%29.png"
                    alt="Instagram"
                    className={
                      i18n.language === 'en'
                        ? 'w-auto h-auto ml-6'
                        : 'w-auto h-auto mr-6'
                    }
                  />
                </a>
              </li>
            </ul>
          </div>
        </main>
      </footer>
    </section>
  );
};
