/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { useTranslation } from 'react-i18next';

const Lobby = ({
  username,
  handleUsernameChange,
  handleSubmit
}: any): JSX.Element => {
  const { t } = useTranslation();
  return (
    <>
      <div className="flex flex-col items-center justify-center w-screen h-screen bg-gray-50 text-gray-700 -mt-40">
        <h1 className="sm:text-3xl text-3xl font-extrabold title-font mb-4 text-gray-900">
          {t('room.join_room')}
        </h1>
        <form
          className="flex flex-col bg-white rounded shadow-lg p-12 "
          action=""
          onSubmit={handleSubmit}
        >
          <label className="font-semibold text-xs">{t('room.your_name')}</label>
          <input
            className="flex items-center h-12 px-4 w-64 bg-gray-50 mt-2 rounded focus:outline-none focus:ring-2"
            type="text"
            value={username}
            onChange={handleUsernameChange}
            required
          />
          <button className="flex items-center justify-center h-12 px-6 w-64 bg-green-800 mt-8 rounded font-semibold text-sm text-white hover:bg-green-900">
            {t('room.join_room')}
          </button>
        </form>
      </div>
    </>
  );
};

export default Lobby;
