/* eslint-disable no-nested-ternary */
/* eslint-disable no-restricted-globals */
import React, { useState, useEffect } from 'react';
//
import { Footer } from 'src/common/Footer';
import { faArrowUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { NewNavbar } from 'src/common/NewNavbar';
import LazyLoad from 'react-lazyload';
import { TierA } from '../featuredService/TierA';
import { TierB } from '../featuredService/TierB';
// import { SignUpCallToAction } from '../SignUpCallToAction';

type LayoutProps = {
  children: React.ReactNode;
};
const pathName = window.location.pathname;

// const userEmail = localStorage.getItem(`email`);

const Layout: React.FC<LayoutProps> = ({ children }: LayoutProps) => {
  //
  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    const updateScroll = () => {
      const scrollY = window.pageYOffset;
      if (scrollY > 0) {
        setScrolled(true);
      }
      if (scrollY === 0) {
        setScrolled(false);
      }
    };
    window.addEventListener('scroll', updateScroll);
  });

  return (
    <div className="min-h-screen bg-white relative">
      {pathName.includes('en/weddingWebsite') ? (
        ''
      ) : (
        <div
          className="sticky top-0 left-0 right-0 pb-0 lg:pb-8"
          style={{
            zIndex: `1000000`
          }}
        >
          <NewNavbar />{' '}
        </div>
      )}

      <div>
        <div>
          <div>{children}</div>
          <LazyLoad height={200}>
            <TierA />
          </LazyLoad>
          <LazyLoad height={200}>
            <TierB />
          </LazyLoad>
        </div>
      </div>
      {/* {!userEmail ? (
        <div
          className="sticky bottom-0"
          style={{
            zIndex: `1000`
          }}
        >
          <SignUpCallToAction />
        </div>
      ) : (
        ``
      )} */}

      <Footer />

      {innerWidth < 700 && scrolled ? (
        <button
          className="fixed block z-50 bg-gray-100 animate-bounce rounded-full w-12 h-12"
          onClick={() =>
            window.scrollTo({
              top: 0,
              left: 0,
              behavior: 'smooth'
            })
          }
          style={{
            bottom: 20,
            left: 25,
            borderRadius: `50%`,
            backgroundColor: `#DF8A40`,
            zIndex: `1000000`
          }}
        >
          <FontAwesomeIcon className="text-3xl text-white" icon={faArrowUp} />
        </button>
      ) : innerWidth > 700 && scrolled ? (
        <button
          className="fixed block z-50 bg-gray-100 animate-bounce rounded-full w-12 h-12"
          onClick={() =>
            window.scrollTo({
              top: 0,
              left: 0,
              behavior: 'smooth'
            })
          }
          style={{
            bottom: 30,
            left: 30,
            backgroundColor: `#DF8A40`,
            color: `white`,
            zIndex: `1000000`
          }}
        >
          <FontAwesomeIcon className="text-3xl text-white" icon={faArrowUp} />
        </button>
      ) : (
        ``
      )}
    </div>
  );
};

export default React.memo(Layout);
