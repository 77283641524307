import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';
import Loader from 'react-loader-spinner';
// import Loader from 'react-loader-spinner';

const Loading: React.FC = () => {
  return (
    <div
      className="h-screen  w-full   flex  flex-col text-center justify-center bg-white"
      style={{
        position: 'absolute',
        zIndex: '999',
        top: 0,
        gap: '14px'
      }}
    >
      <img
        src="https://s3.eu-west-1.amazonaws.com/weds360.com/logo.png"
        className="sm:h-48 sm:w-60 w-48 h-36 mx-auto "
        alt="Weds360"
      />
      <div className="mx-auto">
        <Loader type="TailSpin" color="#024D4C" height={50} width={50} />
      </div>
    </div>
  );
};

export default Loading;
